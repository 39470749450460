import Config from 'config/Config';
import * as crypto from 'crypto';

export default class SecureHelper {


    /**
     * Generates ApiKey for getAccess
     * @param key
     */
    public static generateApiKey(key1: string, key2:string){

        let date = new Date();
        let orgKey = key1 + date.getUTCDate() + date.getUTCMonth() + key2;
        return orgKey;
    }

}
