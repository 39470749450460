import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { _ControlMetrics, _ControlRoom } from "services/api/types/Control";
import { _StatsSummary, _StatsSummaryGame, _StatsSummaryPlayer, _StatsSummaryRound } from "services/api/types/Stats";

export interface IControlStore  {
    currentMetric: _ControlMetrics | undefined,
    columns: any[],
    connections: number,
    rooms: _ControlRoom[],
    roomCount: number,
    roomCountHistory: number[],
    connectionsHistory: number[],

    cpuHistory: number[],
    cpu:number,

    usedMemHistory: number[],
    usedMem: number,
    maxMem: number,

    autoUpdate: boolean,

    ingameClients: number,
    ingameClientsHistory: number[],

    lobbyClients: number,
    lobbyClientsHistory: number[],
    lobbyElapsed: number,

    reloadMetricsCode: number,

    stats: {
      loaded: boolean,
      gameCount: string,
      roundCount: string,
      game: _StatsSummaryGame[],
      round: _StatsSummaryRound[],
      player: _StatsSummaryPlayer[],
    }
  }

export const initialState: IControlStore = {
    currentMetric: undefined,
    columns: [],
    connections: 0,
    rooms: [],
    roomCount: 0,
    roomCountHistory: [],
    connectionsHistory: [],

    cpuHistory: [],
    cpu: 0,

    usedMemHistory: [],
    usedMem: 0,
    maxMem: 0,

    autoUpdate: true,

    //lobby
    ingameClients: 0,
    ingameClientsHistory: [],

    lobbyClients: 0,
    lobbyClientsHistory: [],
    lobbyElapsed: 0,

    reloadMetricsCode: 0,

    stats: {
      loaded: false,
      gameCount: '',
      roundCount: '',
      game: [],
      round: [],
      player: [],
    }
}

/*
 * ACTIONS
 */

export const controlStoreAction = {};


/*
 * REDUCER
 */

const controlStore = createSlice({
  name: "control",
  initialState: initialState,
  reducers: {
    setMetric: (state, action: PayloadAction<_ControlMetrics>) => {
      state.currentMetric = action.payload;

      state.columns = action.payload?.columns;
      state.connections = action.payload?.connections;
      state.rooms = action.payload?.rooms;
      state.roomCount = action.payload?.rooms?.length;
      state.roomCountHistory.push(action.payload?.rooms?.length);
      state.connectionsHistory.push(action.payload?.connections);

      let found = false;
      if(state.rooms.length > 0) {
        state.rooms.forEach(r => {
          if(r.name == 'lobby') {
            found = true;
            state.lobbyClients = r.clients
            state.lobbyClientsHistory.push(r.clients);
            state.ingameClients = (state.connections-state.lobbyClients);
            state.ingameClientsHistory.push(state.ingameClients);
          }
        });
      }
      
      if(!found){
        state.lobbyClients = 0
        state.lobbyClientsHistory.push(0);
        state.ingameClients = (state.connections-state.lobbyClients);
        state.ingameClientsHistory.push(state.ingameClients);
      }

      state.cpu = action.payload?.cpu;
      state.cpuHistory.push(action.payload?.cpu);

      state.usedMem = action.payload?.memory.usedMemMb;
      state.usedMemHistory.push(action.payload?.memory?.usedMemMb);
      state.maxMem = action.payload?.memory?.totalMemMb;


      //CleanUp
      if(state.cpuHistory.length > 30) {
        state.cpuHistory.shift();
        state.usedMemHistory.shift();
        state.lobbyClientsHistory.shift();
        state.ingameClientsHistory.shift();
        state.roomCountHistory.shift();
        state.connectionsHistory.shift();
      }

    },
    setStats: (state, action: PayloadAction<_StatsSummary>) => {
      state.stats.game = action.payload?.game;
      state.stats.round = action.payload?.round;
      state.stats.player = action.payload?.player;

      state.stats.gameCount = action.payload?.gameCount?.count
      state.stats.roundCount = action.payload?.roundCount?.count
      state.stats.loaded = true
      state.reloadMetricsCode = 0
    },
    clearStats: (state, action: PayloadAction) => {
      state.stats.game = [];
      state.stats.round = [];
      state.stats.player = [];

      state.stats.gameCount = "0"
      state.stats.roundCount = "0"
      state.stats.loaded = false
    },
    toggleAutoUpdate: (state, action: PayloadAction) => {
      state.autoUpdate = !state.autoUpdate;
    },
    reloadMetrics: (state, action: PayloadAction) => {
      state.reloadMetricsCode = new Date()?.getTime();
    },
    clearStore: (state, action: PayloadAction) => {
      state = {...initialState};
    },
    //sort: state => state.sort((a, b) => a.message.localeCompare(b.message))
  },
  extraReducers: (builder) => {
    /*builder.addCase(updateUser.rejected, (state, action) => {
      if (action.payload) {
        // Since we passed in `MyKnownError` to `rejectValue` in `updateUser`, the type information will be available here.
        state.error = action.payload.errorMessage
      } else {
        state.error = action.error
      }
    })*/
  },
});


export default controlStore;
