import React from 'react';

export const requestRouteConfig = [
  {
    auth: ['admin'],
    routes: [
      {
        path: '/request/requestlist',
        component: React.lazy(() => import('./RequestList')),
      },
    ],
  },
  {
    auth: ['admin'],
    routes: [
      {
        path: '/request/request/:id',
        component: React.lazy(() => import('./RequestEdit')),
      },
    ],
  },
];
