
export default {
    api: {
        apiUrlDev: 'https://kartlnapidev.jeffsoft.de',
        apiUrlStaging: 'https://test-kartlnapi.kube.kartln.eu', 
        apiUrlProduction: 'https://kartlnapi.kube.kartln.eu', 
        srvUrlDev: 'https://kartlnproxy.jeffsoft.de',
        srvUrlStaging: 'https://test-kartlnsrv.kube.kartln.eu', 
        srvUrlProduction: 'https://kartlnsrv.kube.kartln.eu', 
        apiKey1: 'sZ3ls2Up6cW',
        apiKey2: 'W3fGFus9saAsj',
        srvMetricToken: 'bLpCn2esaTLgjBUESb8g9ssRlghZUQXu',
        srvMetricTokenProduction: 'BGeGwn1eHn9cIvM9M8zAkRFWP4cfTONe',
        timeout: 5000,
    },
    server: {
        dev: {
            host: 'kartlnapi.jeffsoft.de',
            port: '443',
        },
        staging: {
            host: 'test-kartlnapi.kube.kartln.eu',
            port: '443',
        },
        production: {
            host: 'kartlnapi.kube.kartln.eu',
            port: '443',
        },
    },
    debug: 0,
    version: 'v1.7.0',
    buildTxt: '2024-02-07T23:32:19+01:00 - 951830a9',

};
