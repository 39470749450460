
import {_PlayerComplete, _PlayerDetails, _PlayerExp, _PlayerExpsItem, _PlayerLastExp, _PlayersItem} from "./types/Player";
import { _Playerround } from "./types/Game";
import { Api } from "App";
import { Errors } from "data/const/Errors";
import { ErrorMsg } from "data/models/ErrorMsg";
import LogHelper from "helpers/LogHelper";
import { _Account, _AccountsAccount } from "./types/Auth";
import { _ControlMetrics, _ControlRoomStats } from "./types/Control";

export class Control {


    /**
     * Load Metrics of Server inkl open Rooms
     * @param logId
     * @return Promise<boolean>
     */
    static metrics(): Promise<_ControlMetrics | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
            const response = await Api.getOnServer('/control/api?isELK=true', undefined, true, [], false, true);


                if (response && response.columns) {
                        //Parse JSON String "account"
                        let playerDetails = response;
                        LogHelper.logInfo('Services/Api/Control/metrics', ' Control metrics load success');
                        resolve(playerDetails);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Control/metrics', 'Load Control metrics failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Control/metrics', ' Control metrics load failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }
    /**
     * Load Metrics of Server inkl open Rooms
     * @param logId
     * @return Promise<boolean>
     */
    static room(roomId:string): Promise<_ControlRoomStats | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
            const response = await Api.getOnServer('/control/api/room?roomId=' + roomId, undefined, true);


                if (response && response.roomId) {
                        //Parse JSON String "account"
                        let playerDetails = response;
                        LogHelper.logInfo('Services/Api/Control/room', ' Control room load success');
                        resolve(playerDetails);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Control/metrics', 'Load room metrics failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return false;
            } catch (e) {
                LogHelper.logError('Services/Api/Control/metrics', ' Control room load failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return false;
            }
        });

    }

    /**
     * Dispose Room
     * @param roomId
     * @return Promise<boolean>
     */
    static disposeRoom(roomId:string): Promise<true | false> {

        return new Promise(async (resolve: any, reject:any) => {
            
            try {
            const response = await Api.getOnServer('/control/api/room/call?roomId=' + roomId + '&method=disconnect&args=%5B%5D', undefined, true, [], true);

                if (response == true) {
                        //Parse JSON String "account"
                        LogHelper.logInfo('Services/Api/Control/disposeRoom', ' Control disposeRoom load success');
                        resolve(true);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Control/disposeRoom', 'disposeRoomm failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return false;
            } catch (e) {
                LogHelper.logError('Services/Api/Control/disposeRoom', ' disposeRoom failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return false;
            }
        });

    }


    /**
     * Dispose Room
     * @param roomId
     * @return Promise<boolean>
     */
    static loadOnlinePlayers(roomId:string): Promise<true | false> {

        return new Promise(async (resolve: any, reject:any) => {
            
            try {
            const response = await Api.getOnServer('/control/api/room/call?roomId=' + roomId + '&method=getOnlinePlayers&args=%5B%5D', undefined, true, []);

                if (response) {
                    //Parse JSON String "account"
                    LogHelper.logInfo('Services/Api/Control/loadOnlinePlayers', ' Control onlineplayers load success');
                    resolve(response);
                    return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Control/loadOnlinePlayers', 'onlineplayers failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return false;
            } catch (e) {
                LogHelper.logError('Services/Api/Control/loadOnlinePlayers', ' onlineplayers failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return false;
            }
        });

    }

    /**
     * Dispose Room
     * @param roomId
     * @return Promise<boolean>
     */
    static kickPlayerFromRoom(roomId:string, playerId:string): Promise<true | false> {

        return new Promise(async (resolve: any, reject:any) => {
            
            try {

            const uriData = JSON.stringify([playerId,'',4007,4007,true]);

            const response = await Api.getOnServer('/control/api/room/call?roomId=' + roomId + '&method=closeClientByPlayerId&args=' + encodeURI(uriData), undefined, true, [], true);

                if (response) {
                    //Parse JSON String "account"
                    LogHelper.logInfo('Services/Api/Control/kickPlayerFromRoom', ' Control kickPlayerFromRoom load success');
                    resolve(response);
                    return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Control/kickPlayerFromRoom', 'kickPlayerFromRoom failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return false;
            } catch (e) {
                LogHelper.logError('Services/Api/Control/kickPlayerFromRoom', ' kickPlayerFromRoom failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return false;
            }
        });

    }

    /**
     * Dispose Room
     * @param roomId
     * @return Promise<boolean>
     */
    static sendMessage(roomId:string, type:string, message:string): Promise<true | false> {

        return new Promise(async (resolve: any, reject:any) => {
            try {

            const uriData = JSON.stringify(["serverMessage", {
                module: "ROOM",
                state: "INFO",
                action: "SYSTEM_TOAST_MESSAGE",
                data: {
                    type: type,
                    message: message
                }
            }]);

            const response = await Api.getOnServer('/control/api/room/call?roomId=' + roomId + '&method=broadcast&args=' + encodeURI(uriData), undefined, true, [], true);

                if (response == true) {
                        //Parse JSON String "account"
                        LogHelper.logInfo('Services/Api/Control/sendMessage', ' Control sendMessage load success');
                        resolve(true);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Control/sendMessage', 'sendMessage failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return false;
            } catch (e) {
                LogHelper.logError('Services/Api/Control/sendMessage', ' sendMessage failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return false;
            }
        });

    }


    /**
     * Dispose Room
     * @param roomId
     * @return Promise<boolean>
     */
    static sendMessageRoomPlayer(roomId:string, playerId:any, type:string, message:string): Promise<true | false> {

        return new Promise(async (resolve: any, reject:any) => {
            try {

            const uriData = JSON.stringify([playerId,"ROOM","INFO", "SYSTEM_TOAST_MESSAGE",
                {
                    type: type,
                    message: message
                }
            ]);

            const response = await Api.getOnServer('/control/api/room/call?roomId=' + roomId + '&method=sendUser&args=' + encodeURI(uriData), undefined, true, [], true);

                if (response == true) {
                        //Parse JSON String "account"
                        LogHelper.logInfo('Services/Api/Control/sendMessageRoomPlayer', ' Control sendMessageRoomPlayer load success');
                        resolve(true);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Control/sendMessageRoomPlayer', 'sendMessageRoomPlayer failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return false;
            } catch (e) {
                LogHelper.logError('Services/Api/Control/sendMessageRoomPlayer', ' sendMessageRoomPlayer failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return false;
            }
        });

    }

    

    /**
     * Dispose Room
     * @param roomId
     * @return Promise<boolean>
     */
     static sendNotification(targets:string, title:string, message:string, addData:any): Promise<true | false> {

        return new Promise(async (resolve: any, reject:any) => {
            try {
            let target = ('' + targets)?.split(',');

            const data = {
                players: target,
                title: title,
                message: message,
                data: addData
            }

            const response = await Api.post('/admin/control/sendNotificationPlayers', data, true);

                if (response && response.notify == true) {
                        //Parse JSON String "account"
                        LogHelper.logInfo('Services/Api/Control/sendNotification', ' Control sendNotification send success');
                        resolve(true);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Control/sendNotification', 'sendNotification failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Control/sendNotification', ' sendNotification failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }

    /**
     * Dispose Room
     * @param roomId
     * @return Promise<boolean>
     */
     static sendNotificationToAll(title:string, message:string): Promise<true | false> {

        return new Promise(async (resolve: any, reject:any) => {
            try {

            const data = {
                title: title,
                message: message
            }

            const response = await Api.post('/admin/control/sendNotificationToAll', data, true);

                if (response && response.notify == true) {
                        //Parse JSON String "account"
                        LogHelper.logInfo('Services/Api/Control/sendNotificationToAll', ' Control sendNotificationToAll send success');
                        resolve(true);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Control/sendNotificationToAll', 'sendNotificationToAll failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Control/sendNotificationToAll', ' sendNotificationToAll failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }

    /**
     * Dispose Room
     * @param roomId
     * @return Promise<boolean>
     */
     static setAppVersions(appVersion:string, appVersions:any): Promise<true | false> {

        return new Promise(async (resolve: any, reject:any) => {
            try {

            const data = {
                appVersion,
                appVersions,
            }

            const response = await Api.post('/admin/control/setAppVersions', data, true);

                if (response) {
                        //Parse JSON String "account"
                        LogHelper.logInfo('Services/Api/Control/setAppVersions', ' Control setAppVersions send success');
                        resolve(true);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Control/setAppVersions', 'setAppVersions failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Control/setAppVersions', ' setAppVersions failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }
}
