import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {useDispatch} from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import useStyles from './AppHeader.style';
import clsx from 'clsx';
import appStore from 'store/app.store';
import ControlMetricsHeader from 'components/header/ControlMetricsHeader';
import EnvSwitch from 'components/core/EnvSwitch';

interface AppHeaderProps {}

const AppHeader: React.FC<AppHeaderProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <>
      <AppBar color='inherit' className={clsx(classes.appBar, 'app-bar')}>
        <Toolbar className={classes.appToolbar}>
          <Hidden lgUp>
            <IconButton
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='open drawer'
              onClick={() => dispatch(appStore.actions.toggleNavCollapse())}>
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </Hidden>
          <EnvSwitch />
          <Box className={classes.grow} />
          <Box >
            <ControlMetricsHeader />
            {/*<HeaderMessages />
            <Notifications />*/}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};
export default AppHeader;
