import { setAuthToken } from '@crema/services/auth/jwt-auth/jwt-api';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import apiStore from './api.store';
import appStore from './app.store';
import controlStore from './control.store';
import sessionStore from './session.store';


const createBrowserHistory = require('history').createBrowserHistory;
export const history = createBrowserHistory();


const rootReducer = combineReducers({
  session: sessionStore.reducer,
  app: appStore.reducer,
  api: apiStore.reducer,
  control: controlStore.reducer,
  router: connectRouter(history)
});


const middleware = [thunk, routerMiddleware(history)];

const enhancers = [];
const windowIfDefined =
  typeof window === 'undefined' ? null : (window as any);
if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
  enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
}


export const store = configureStore({
  reducer: rootReducer,
  enhancers: enhancers,
  middleware: middleware
});

let currentState = store.getState();
store.subscribe(() => {
  // keep track of the previous and current state to compare changesAppLayout/index.j
  let previousState = currentState;
  currentState = store.getState();
  // if the token changes set the value in localStorage and axios headers
  if (previousState.session.token !== currentState.session.token) {
    const token = currentState.session.token;
    setAuthToken(token);
  }
});




export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;


