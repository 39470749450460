import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import SortIco from '@material-ui/icons/Games';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { _ControlRoom } from 'services/api/types/Control';
import { RootState } from 'store/store';
import { DarkBrown } from 'shared/constants/Colors';
import appStore from 'store/app.store';
import sessionStore, { sessionStoreAction } from 'store/session.store';

interface IProps {
  small?:boolean
}

const EnvSwitch = (props:IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  
  const storeDispatch: any = useDispatch<any>();
  const storeEnv = useSelector((state: RootState) => state.app.env);


  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const _toggleEnv = (env:'DEV'|'STAGING'|'PRODUCTION') => {
    handleClose();
    storeDispatch(appStore.actions.switchEnv(env));
    storeDispatch(sessionStoreAction.initConnection());
    storeDispatch(sessionStoreAction.logoutUser());
  };

  return (
    <Box ml={0}color={'white'}>
      <MenuItem
        aria-controls='sort'
        aria-haspopup='true'
        aria-label='asfas'
        style={{backgroundColor: DarkBrown, color:'white', paddingLeft:15}}
        onClick={handleClick}>
        <SortIco />
        <Box
              display='flex'
              flexDirection='row'
              alignItems='center'
              fontSize={props.small ? 14 : 20}
              fontWeight={'bold'}
              ml={2}
              justifyContent='space-between'>
        {storeEnv}
        </Box>
      </MenuItem>
      <Menu
        id='fade-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}>
        <MenuItem style={{fontSize: 16, backgroundColor:(storeEnv == 'DEV' ? DarkBrown : 'white'),color:(storeEnv == 'DEV' ? 'white' : DarkBrown), fontWeight: (storeEnv == 'DEV' ? 'bold' : 'normal')}} onClick={() => _toggleEnv('DEV')}>
          DEV
        </MenuItem>
        <MenuItem style={{fontSize: 16,  backgroundColor:(storeEnv == 'STAGING' ? DarkBrown : 'white'),color:(storeEnv == 'STAGING' ? 'white' : DarkBrown), fontWeight: (storeEnv == 'STAGING' ? 'bold' : 'normal')}} onClick={() => _toggleEnv('STAGING')}>
          STAGING
        </MenuItem>
        <MenuItem style={{fontSize: 16,  backgroundColor:(storeEnv == 'PRODUCTION' ? DarkBrown : 'white'),color:(storeEnv == 'PRODUCTION' ? 'white' : DarkBrown), fontWeight: (storeEnv == 'PRODUCTION' ? 'bold' : 'normal')}} onClick={() => _toggleEnv('PRODUCTION')}>
          PRODUCTION
        </MenuItem>
      </Menu>
    </Box>
  );
};
export default EnvSwitch;
