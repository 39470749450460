import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import LogHelper from "helpers/LogHelper";
import { Game } from "services/api/Game";
import { Player } from "services/api/Player";
import { CACHETIME } from "services/api/types/Enum";
import { RootState } from "./store";
import { isCacheExpired } from "./StoreHelper";

export interface IApiCacheItem<T = any>  {
    state: number,
    time: number,
    payload: any,
    data: T
  }
export interface IApiStore  {
    cache: any
  }

export const initialState: IApiStore = {
  cache: {
    profile_detail: {
      state: 0,
      time: 0,
      payload: undefined,
      data: undefined
    },
    profile_complete: {
      state: 0,
      time: 0,
      payload: undefined,
      data: undefined
    },
    profile_games: {
      state: 0,
      time: 0,
      payload: undefined,
      data: undefined
    },
    profile_lastexps: {
      state: 0,
      time: 0,
      payload: undefined,
      data: undefined
    },
    playersmeta: {
      state: 0,
      time: 0,
      payload: undefined,
      data: undefined
    },
    game: {
      state: 0,
      time: 0,
      payload: undefined,
      data: undefined
    },
    gamerounds: {
      state: 0,
      time: 0,
      payload: undefined,
      data: undefined
    },
    round: {
      state: 0,
      time: 0,
      payload: undefined,
      data: undefined
    },
    playerround: {
      state: 0,
      time: 0,
      payload: undefined,
      data: undefined
    },
    playerexp: {
      state: 0,
      time: 0,
      payload: undefined,
      data: undefined
    },
  },
}

/*
 * ACTIONS
 */


export const apiStoreAction = {
    /* profile */
    profile_complete: createAsyncThunk<void, any, {state: RootState}>('api/profile_complete', async (payload, thunkApi) => {
      try {
        const playerId = payload;
        const current = thunkApi.getState()?.api?.cache?.profile_complete;
        if(current?.payload == playerId && !isCacheExpired(current?.time, CACHETIME.TWO_MINUTE)) {
          return;
        }
        thunkApi.dispatch(apiStore.actions.clearCache('profile_complete'));
        const result = await Player.playerComplete(playerId)
        if(!result){
           thunkApi.dispatch(apiStore.actions.setFailed('profile_complete'));
        }else{
          thunkApi.dispatch(apiStore.actions.setSuccess({action: 'profile_complete', payload: playerId, data: result}));
        }
      } catch (e) {
          LogHelper.logError('Store/api/action/profile_complete', 'Loading profile_complete failed: ', e);
          thunkApi.dispatch(apiStore.actions.setFailed('profile_complete'));
      }
    }),  
    profile_detail: createAsyncThunk<void, any, {state: RootState}>('api/profile_detail', async (payload, thunkApi) => {
      try {
        const playerId = payload;
        const current = thunkApi.getState()?.api?.cache?.player_detail;
        if(current?.payload == playerId && !isCacheExpired(current?.time, CACHETIME.ONE_MINUTE)) {
          return;
        }
        thunkApi.dispatch(apiStore.actions.clearCache('profile_detail'));
        const result = await Player.playerDetails(playerId)
        if(!result){
            thunkApi.dispatch(apiStore.actions.setFailed('profile_detail'));
          }else{
            thunkApi.dispatch(apiStore.actions.setSuccess({action: 'profile_detail', payload: playerId, data: result}));
          }
      } catch (e) {
          LogHelper.logError('Store/api/action/profile_detail', 'Loading profile_detail failed: ', e);
          thunkApi.dispatch(apiStore.actions.setFailed('profile_detail'));
      }
    }),    
    profile_lastexps: createAsyncThunk<void, string, {state: RootState}>('api/profile_lastexps', async (payload, thunkApi) => {
      try {
        const current = thunkApi.getState().api.cache.profile_lastexps
          if(current?.data && !isCacheExpired(current?.time, CACHETIME.TWO_MINUTE)){
            return;
          }
        const result = await Player.profile_lastexps(payload);
        if(!result) {
           thunkApi.dispatch(apiStore.actions.setFailed('profile_lastexps'));
          }else{
            thunkApi.dispatch(apiStore.actions.setSuccess({action: 'profile_lastexps', payload: payload, data: result}));
          }
      } catch (e) {
          LogHelper.logError('Store/api/action/profile_lastexps', 'Loading profile_lastexps failed: ', e);
          thunkApi.dispatch(apiStore.actions.setFailed('profile_lastexps'));
      }
    }),  
    profile_games: createAsyncThunk<void, any, {state: RootState}>('api/profile_games', async (payload, thunkApi) => {
      try {
        const playerId = payload;
        const current = thunkApi.getState().api.cache.profile_games;
        if(current?.payload == playerId && !isCacheExpired(current?.time, CACHETIME.ONE_MINUTE)){
          return;
        }
        thunkApi.dispatch(apiStore.actions.clearCache('profile_games'));
        const result = await Player.player_games(playerId, 'date', true, 0, 4);
        if(!result) {
           thunkApi.dispatch(apiStore.actions.setFailed('profile_games'));
          }else{
            thunkApi.dispatch(apiStore.actions.setSuccess({action: 'profile_games', payload:playerId, data: result}));
          }
      } catch (e) {
          LogHelper.logError('Store/api/action/profile_games', 'Loading profile_games failed: ', e);
          thunkApi.dispatch(apiStore.actions.setFailed('profile_games'));
      }
    }) ,  
    playersmeta: createAsyncThunk<void, string, {state: RootState}>('api/playersmeta', async (payload, thunkApi) => {
      try {
        const current = thunkApi.getState().api.cache.playersmeta;
        if(current.payload == payload && !isCacheExpired(current?.time, CACHETIME.TWO_MINUTE)) {
          return;
        }
        thunkApi.dispatch(apiStore.actions.clearCache('playersmeta'));
        const result = await Player.playersMeta(payload);
        if(!result)  {
          thunkApi.dispatch(apiStore.actions.setFailed('playersmeta'));
        }else{
          thunkApi.dispatch(apiStore.actions.setSuccess({action: 'playersmeta', payload: payload,  data: result}));
        }
      } catch (e) {
          LogHelper.logError('Store/api/action/playersmeta', 'Loading playersmeta failed: ', e);
          thunkApi.dispatch(apiStore.actions.setFailed('playersmeta'));
      }
    }),  
    game: createAsyncThunk<void, {gameId:string, withMeta?:boolean}, {state: RootState}>('api/game', async (payload, thunkApi) => {
      try {
        const current = thunkApi.getState().api.cache.game;
        /*if(current?.payload == payload.gameId && current?.data) {
          if(payload.withMeta && current?.data) {
            thunkApi.dispatch(apiStoreAction.playersmeta(current?.data?.players));
          }
          return;
        }
        thunkApi.dispatch(apiStore.actions.clearCache('game'));
        */
        const result = await Game.game(payload.gameId);
        if(!result) {
           thunkApi.dispatch(apiStore.actions.setFailed('game'));
          }else{
            if(payload.withMeta && result) {
              thunkApi.dispatch(apiStoreAction.playersmeta(result.players));
            }
            thunkApi.dispatch(apiStore.actions.setSuccess({action: 'game', payload: payload.gameId, data: result}));
          }
        
      } catch (e) {
          LogHelper.logError('Store/api/action/game', 'Loading game failed: ', e);
          thunkApi.dispatch(apiStore.actions.setFailed('game'));
      }
    }),   
    round: createAsyncThunk<void, string, {state: RootState}>('api/round', async (payload, thunkApi) => {
      try {
        const current = thunkApi.getState().api.cache.round;
        if(current.payload == payload && payload) {
            //Load GameDetails
            thunkApi.dispatch(apiStoreAction.game({gameId: current?.data?.gameid, withMeta: true}));
          return;
        }
        thunkApi.dispatch(apiStore.actions.clearCache('round'));
        const result = await Game.round(payload);
        if(!result)  {
          thunkApi.dispatch(apiStore.actions.setFailed('round'));
        }
        //Load GameDetails
        if(result) {
          thunkApi.dispatch(apiStoreAction.game({gameId: result.gameid+'', withMeta: true}));
          thunkApi.dispatch(apiStore.actions.setSuccess({action: 'round', payload: payload, data: result}));
        }
      } catch (e) {
          LogHelper.logError('Store/api/action/round', 'Loading round failed: ', e);
          thunkApi.dispatch(apiStore.actions.setFailed('round'));
      }
    }),  
    playerround: createAsyncThunk<void, {playerId:string, roundId:number}, {state: RootState}>('api/playerround', async (payload, thunkApi) => {
      try {
        if(thunkApi.getState().api.cache.playerround.payload == payload) {
          return;
        }
        thunkApi.dispatch(apiStore.actions.clearCache('playerround'));
        const result = await Player.playerround(payload.playerId, payload.roundId);
        if(!result) {
           thunkApi.dispatch(apiStore.actions.setFailed('playerround'));
          }else{
            thunkApi.dispatch(apiStore.actions.setSuccess({action: 'playerround', payload: payload, data: result}));
          }
      } catch (e) {
          LogHelper.logError('Store/api/action/round', 'Loading playerround failed: ', e);
          thunkApi.dispatch(apiStore.actions.setFailed('playerround'));
      }
    })  ,  
    playerexp: createAsyncThunk<void, {playerId:string, type:string, actionId:number}, {state: RootState}>('api/playerexp', async (payload, thunkApi) => {
      try {
        const current = thunkApi.getState().api.cache.playerexp;
        if(current?.payload == payload && !isCacheExpired(current?.time, CACHETIME.TWO_MINUTE)){
          return;
        }
        thunkApi.dispatch(apiStore.actions.clearCache('playerexp'));
        const result = await Player.playerexp(payload.playerId, payload.type, payload.actionId);
        if(!result) {
           thunkApi.dispatch(apiStore.actions.setFailed('playerexp'));
          }else{
            thunkApi.dispatch(apiStore.actions.setSuccess({action: 'playerexp', payload: payload, data: result}));
          }
      } catch (e) {
          LogHelper.logError('Store/api/action/round', 'Loading playerexp failed: ', e);
          thunkApi.dispatch(apiStore.actions.setFailed('playerexp'));
      }
    })  
}


/*
 * REDUCER
 */

const apiStore = createSlice({
  name: "api",
  initialState: initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<string>) => {
      state.cache[action.payload].state = 1;
    },
    setSuccess: (state, action: PayloadAction<{action: string, payload:any,  data: any}>) => {
      state.cache[action.payload.action] = {state: 2, time: new Date().getTime(), payload: action.payload.payload, data: action.payload.data};
    },
    setFailed: (state, action: PayloadAction<string>) => {
      state.cache[action.payload] = {state: 3, time: 0, payload: undefined, data: undefined};
    },
    clearCache: (state, action: PayloadAction<string>) => {

      state.cache[action.payload] = {state: 0, time: 0, payload: undefined, data: undefined};
    },
    clearStore: (state, action: PayloadAction) => {
      state = {...initialState}
    }
    //sort: state => state.sort((a, b) => a.message.localeCompare(b.message))
  },
  extraReducers: (builder) => {
  }
  
});


export default apiStore;
