export default  {
    KARTLN: 'Kartln',

    //NORMAL
    OK: 'Ok',
    WELCOME: 'Willkommen',
    RETRY_CONNECT: 'Erneut verbinden',
    NEW_CONNECT: 'Neu verbinden',
    JOIN_TIMEOUT: 'Zeit abgelaufen',
    SESSION_ENDED: 'Sitzung beendet',
    BACK_TO_LOBBY: 'Zurück zur Lobby',
    RETRY_TRY: 'Erneut versuchen',
    BACK: 'Zurück',
    PLAYER: 'Spieler',
    PLAYERSLIST: 'Spielerliste',
    SORT_BY: 'Sortieren nach...',
    SEARCH_DOTS: 'Suchen...',
    GAMES: 'Spielerunden',
    GAMES_SHORT: 'Spiele',
    SEARCH: 'Suchen',
    SORT_DOWN: 'Absteigend',
    SORT_UP: 'Aufsteigend',
    POINTS: 'Punkte',
    MY_ROUND: 'Meine Runde...',
    ROUND: 'Runde',
    ROUND_OVERVIEW: 'Rundenübersicht',
    LOBBY: 'Lobby',
    READY: 'Bereit',
    LEAVE: 'Verlassen',
    ABORT: 'Abbrechen',
    RANK: 'Platz',
    CLOSE: 'Schließen',
    END: 'Beenden',
    INFORMATION: 'Information',
    LEVEL: 'Level',
    ONLINE_UNTIL: 'Online seit',
    ONLINE: 'Online',
    REGISTERED_UNTIL: 'Dabei seit',
    ROUNDS: 'Runden',
    GAMEMODE: 'Modus',
    DATE: 'Datum',
    NAME: 'Name',
    FINALSCORE: 'Zielpunkte',
    ROUNDS_PLAYED: 'Runden gespielt',
    REACHSCORE_WINNED: 'Punktespiele gewonnen',
    LAST_4_ROUNDS: 'Letzen Runden..',
    JOINED: 'Beigetreten',
    PLEASE_TRY_AGAIN: 'Bitte erneut versuchen.',
    RELOAD_APP: 'App neustarten',
    ROOMS_AVAILABLE: 'Verfügbare Spielräume',
    YOUR_PROFILEINFOS: 'Deine Profildetails',
    PLAYERS_INGAME: 'Spieler im Spiel',
    MY_PROFILE: 'Mein Profil',
    MY_STATS_AND_HISTORY: 'Verlauf und Fortschritt',
    MY_STATISTICS: 'Meine Statistik',
    PLAYER_STATISTIC: 'Spielerstatistik',
    MY_XP_STATS: 'Erfahrungsstatistik',
    LAST_RECEIVED_XP: 'Zuletz erhaltene Erfahrung',
    XP_LAST_DAYS: 'Erfahrungsverlauf (14 Tage)',
    SUMMARY_XP_LAST_DAYS: 'Erhaltene Erfahrung',
    TIMES_TAKE_XP: 'Anzahl',
    LAST_DAYS: 'Letzen 14 Tage',
    ACTIONS: 'Aktionen',
    PLAYER_WIN_QUOTE: 'Siegesquote',
    ROOM_FINISHED_COMPLETE: 'Spiel abgeschlossen',
    LAST_GAMEROOMS: 'Letzte Spiele',
    SHOW_DETAILS_OF_XP: 'Details zur Erfahrung anzeigen...',
    DESC_PLAY: 'Spielraum erstellen oder offene Räume anzeigen',
    DESC_GAMESLIST: 'Spielräume durchsuchen und Statistiken anzeigen',
    DESC_PLAYERSLIST: 'Spieler durchsuchen und anzeigen',
    DESC_PROFILE: 'Meine Statistiken anzeigen',

    PLAYERS_ONLINE: 'Spieler online',

    PERFORM_BEST: 'Beste',
    PERFORM_GOBEST: 'Sehr gut',
    PERFORM_GOOD: 'Gut',
    PERFORM_GOGOOD: 'Wird Besser',
    PERFORM_MIDDLE: 'Durchschnittlich',
    PERFORM_BAD: 'Schlecht',
    PERFORM_UNKNOWN: 'Unbekannt',
    
    //AUTH
    SIGN_IN: 'Anmelden',
    SIGN_OUT: 'Abmelden',
    SIGN_OUT_SUBTEXT: 'Möchtest du dich wirklich abmelden?',
    SIGN_UP: 'Neues Konto',
    SIGN_UP_DOTS: 'Neues Konto erstellen...',
    SIGN_UP_START: 'Konto erstellen',
    SIGN_UP_TERMS: 'Bist du mit den Nutzungs-/ und Datenschutzbestimmungen einverstanden? Klicke auf diesen Text um diese zu lesen.',
    SIGN_UP_TERM_TITLE: 'Nutzungs-/ und Datenschutzbestimmungen',
    SIGN_UP_TERM_SUBTITLE: 'Lies dir die Bestimmungen aufmerksam durch und betätige diese anschließend, um deine Registrierung abzuschließen.',
    FORGOT_PASSWORD: 'Passwort vergessen',
    ALREADY_HAVE_TOKEN: 'Ich habe bereits einen Token..',
    LOGIN: 'Anmeldung',
    CHANGE_PASSWORD: 'Passwort ändern',
    PASSWORD: 'Passwort',
    TOKEN: 'Token aus E-Mail',
    PASSWORD_RETRY: 'Passwort wiederholen',
    EMAIL_ADDRESS: 'E-Mail-Adresse',
    EMAIL_ADDRESS_RETRY: 'E-Mail-Adresse wiederholen',
    REGISTER: 'Registrieren',
    REGISTER_RUN_TITLE: 'Registrierung läuft...',
    REGISTER_SUCCESS: 'Registrierung erfolgreich!',
    CHANGE_PASSWORD_SUCCESS: 'Passwortänderung erfolgreich!',
    JOIN_LOBBY_SOON: 'Du wirst gleich zur Anmeldung weitergeleitet...',
    REQUEST_SUCCESS: 'Anfrage erfolgreich!',
    REQ_FORGOT_SUCCESS: 'Gibt es ein Konto mit dieser E-Mail erhälst du gleich einen Token.',
    SET_NEW_PASSWORD: 'Neues Passwort setzen',
    PLAYERNAME: 'Spielername',
    LOGIN_SUCCESS: 'Erfolgreich angemeldet',
    LOGIN_FAILED: 'Anmeldung fehlgeschlagen',
    SIGN_IN_IN_PROGRESS_DOTS: 'Anmeldung läuft...',
    PLEASE_WAIT: 'Bitte warten',
    GAME_HISTORY: 'Spielverlauf',
    GAME_ROUNDS: 'Spielrunden',

    //LOBBY
    JOINING_FAILED: 'Verbindung fehlgeschlagen',
    CONNECT_TO_LOBBY: 'Verbindung zur Lobby aufbauen...',
    RECONNECT_TO_LOBBY: 'Wiederverbinden mit Lobby...',
    RECONNECTING_DESC: 'Es wird versucht die Verbindung wiederherzustellen.',
    AUTO_RECONNECT_FAILED: 'Erneut verbinden fehlgeschlagen!',
    AUTO_RECONNECT_FAILED_DESC: 'Die Verbindung konnte nicht automatisch wiederhergestellt werden. Versuche es erneut',
    AUTO_RECONNECT_FAILED_AGAIN_DESC: 'Die Verbindung konnte nicht wiederhergestellt werden, prüfe deine Internetverbindung und verbinde dich neu.',
    CONNECT_FAILED: 'Verbinden fehlgeschlagen!',
    RECONNECTING: 'Erneut verbinden...',
    NEW_CONNECTING: 'Neu verbinden...',
    NEW_CONNECTING_DESC: 'Es wird versucht eine neue Verbindung aufzubauen.',
    LOBBY__LEAVED: 'Lobby wurde verlassen',
    LOBBY__LEAVED_BY_ERROR: 'Verbindung zur Lobby wurde unterbrochen',
    ALREADY_LOGGED_IN: 'Bereits angemeldet',
    ALREADY_LOGGED_INFO: 'Du bist derzeit noch mit einer Sitzung angemeldet, möchtest du dich trotzdem verbinden?',
    ALREADY_IN_GAME: 'Du hast ein aktives Spiel!',
    ALREADY_IN_GAME_DESC: 'Möchtest du dem Spielraum wieder beitreten?',

    //CHAT
    WRITE_MESSAGE_DOTS: 'Nachricht schreiben...',
    CHAT_GAMEROOM: 'Spielchat',
    CHAT_LOBBY: 'Lobbychat',
    JOIN_ROOM: 'Raum beitreten',
    SEND_SHORT_MESSAGE: 'Kurznachricht senden',

    CHAT__WHY_DOES_IT_TAKE_SO_LONG: 'Was dauert hier so lang?',
    CHAT__HELLO_BAVARIA: 'Griaßeich!',
    CHAT__HAHA: 'Haha...',
    CHAT__GO: 'Los!',
    CHAT__NOT_SO_FAST: 'Nicht so schnell :D',
    CHAT__WOW_BAVARIA: 'Oida!',
    CHAT__YOU_PULL_ONCE: 'Ziehst erstmal eine...',
    CHAT__WHAT_THE_HELL: 'Was zur Hölle...',
    CHAT__GOOD_GAME: 'Gutes Spiel',

    //ERRORS
    ERR_IS_OCCURRED: 'Ein Fehler ist aufgetreten!',
    ERR_REQUIRED_INPUTS_MISSING: 'Erforderliche Eingabe fehlt!',
    ERR_PASSWORDS_NOT_EQUAL: 'Passwörter stimmen nicht überein!',
    ERR_EMAILS_NOT_EQUAL: 'E-Mail Adressen stimmen nicht überein!',
    ERR_EMAIL_NOT_VALID: 'E-Mail Adressen ist ungültig!',
    ERR_INPUT_HAS_NOT_VALID_CHARS: 'Eingabe enthält nicht erlaubte Zeichen!',
    ERR_USER_HAS_BLOCKED_CHARS: 'Benutzername enthält nicht erlaubte Zeichen!',
    ERR_USER_NEED_TERMS_CHECKED: 'Du musst den Bestimmungen zustimmen!',
    ERR_PREPARE_SEND_FEEDBACK_FAILED: 'Vorbereitung zum senden eines Feedbacks fehlgeschlagen!',

    ERR_SERVER__CONNECTION_FAILED: 'Verbindung zum Server konnte nicht hergestellt werden',
    ERR_API__TIMEOUT: 'Verbindung konnte nicht hergestellt werden. Zeitüberschreitung',

    ERR_API__REQUEST_FAILED: 'Anfrage an ContentServer fehlgeschlagen.',

    ERR_AUTH__LOGIN_NOT_SAVED: 'Anmeldung konnte nicht gespeichert werden',
    ERR_AUTH__LOGIN_FAILED: 'Anmeldung fehlgeschlagen!',
    ERR_AUTH__LOGOUT_FAILED: 'Abmeldung fehlgeschlagen!',

    ERR_AUTH__REGISTER_FAILED: 'Registrierung fehlgeschlagen!',
    ERR_AUTH__REGISTER_FAILED_BY_EXC: 'Registrierung aufgrund eines Fehlers fehlgeschlagen!',
    ERR_AUTH__REGISTER_FAILED_USED_USERNAME: 'Benutzername oder E-Mail wird bereits verwendet!',
    ERR_AUTH__REGISTER_FAILED_BLOCKED: 'Registrierung mit diesen Daten nicht möglich!',

    ERR_AUTH__REQUEST_FORGOT_FAILED: 'Anfrage zur Passwortrücksetzung ist fehlgeschlagen!',
    ERR_AUTH__SET_FORGOT_FAILED: 'Setzen des neuen Passworts ist fehlgeschlagen!',

    ERR_CON__CONNECTION_FAILED: 'Verbindung konnte nicht hergestellt werden!',
    ERR_CON__CONNECTION_LOST: 'Verbindung verloren!',

    ERR_CHAT__SEND_MESSAGE_FAILED: 'Nachricht konnte nicht versendet werden!',

    ERR_LOBBY__GENERAL_ERROR: 'Ein Fehler in der Lobby ist aufgetreten!',
    ERR_LOBBY__JOIN_FAILED: 'Es konnte keine Verbindung zum LobbyServer hergestellt werden. Prüfe ggf. deine Internetverbindung.',
    ERR_LOBBY__JOIN_FAILED_TIMEOUT: 'Verbindung fehlgeschlagen, da du bereits angemeldet bist!',
    ERR_LOBBY__JOIN_FAILED_FORCE_LOGIN_ABORT: 'Verbindung wurde getrennt, da du bereits angemeldet bist!',
    ERR_SESSION_EXPIRED_BY_FORCE_LOGIN: 'Verbindung wurde getrennt, da du dich auf einem anderen Gerät angemeldet hast.',
    ERR_SESSION_EXPIRED_BY_NO_FORCE_LOGIN: 'Verbindung wurde getrennt, da die Zeit der Loginabfrage abgelaufen ist.',
    ERR_SESSION_EXPIRED_BY_TRY_JOIN_GAME_FAILED: 'Verbindung wurde getrennt, da der Verbindungsaufbau fehlgeschlagen ist.',
    ERR_SESSION_EXPIRED_BY_TRY_JOIN_FAILED: 'Verbindung wurde getrennt, da der beitritt zum Spielraum fehlgeschlagen ist.',
    ERR_SESSION_EXPIRED_BY_KICKED: 'Verbindung wurde getrennt, da du aus dem Raum geworfen wurdest!.',
    ERR_SERVER_NOT_AVAILABLE_OR_NO_INET: 'Verbindung zum Server kann nicht hergestellt werden. Prüfe ggf. deine Internetverbindung.',

    ERR_GAME__LOADING_ROOMS_FAILED: 'Laden der Spielräume ist fehlgeschlagen',
    ERR_GAME__GENERAL_ERROR: 'Ein Fehler im Spielraum ist aufgetreten!',
    ERR_GAME__LEAVE_FAILED: 'Der Spielraum konnte nicht verlassen werden!',
    ERR_GAME__CREATEROOM_FAILED: 'Spielraum konnte nicht erstellt werden!',
    ERR_GAME__JOINROOM_FAILED: 'Spielraum konnte nicht beigetreten werden!',
    ERR_GAME__JOINROOM_FAILED_ALREADY_IN_GAME: 'Beitritt fehlgeschlagen! Du befindest dich bereits in einem Spielraum!',
    ERR_GAME__RECONNECT_LOBBY_FAILED: 'Erneutes verbinden mit der Lobby ist fehlgeschlagen',
    ERR_GAME__RECONNECT_LOBBY_FAILED_AGAIN: 'Erneutes verbinden mit der Lobby zu oft fehlgeschlagen!',
    ERR_GAME__REJOIN_LOBBY_FAILED: 'Neu Verbinden mit der Lobby ist fehlgeschlagen!',
    ERR_GAME__PLAYER_ACTION_FAILED: 'Aktion fehlgeschlagen',
    ERR_GAME__BLOCKED_BY_ACTION: 'Spiel wegen Aktion unterbrochen',
    ERR_GAME__JOINROOM_FAILED_WRONG_PASS: 'Beitreten fehlgeschlagen. Passcode falsch',
    ERR_GAME__JOINROOM_FAILED_MAX_PLAYER_REACHED: 'Der Raum ist bereits voll!',
    ERR_GAME__JOINROOM_FAILED_GAME_IS_RUNNING: 'Beitreten fehlgeschlagen. Spiel läuft bereits!',
    ERR_GAME__FORCELOGIN_FAILED_NOOPTIONS: 'Erneutes Beitreten des laufenden Spiels fehlgeschlagen. Verbindungsinformationen fehlen',
    ERR_GAME__FORCELOGIN_FAILED: 'Erneutes Beitreten des laufenden Spiels fehlgeschlagen. Ein Fehler ist aufgetreten',
    ERR_GAME__RECONNECT_FAILED: 'Erneutes verbinden mit dem Spielraum ist fehlgeschlagen.',
    ERR_GAME__RECONNECT_FAILED_AGAIN: 'Erneutes verbinden mit dem Spielraum ist zu oft fehlgeschlagen.',
    ERR_GAME__JOINROOM_FAILED_ROOM_CLOSED: 'Spielraum ist bereits geschlossen. Verbindung nicht möglich!',
    ERR_GAME__JOINROOM_FAILED_ROOM_LOCKED: 'Spielraum ist gesperrt. Verbindung nicht möglich!',
    ERR_GAME__JOINROOM_FAILED_NOT_POSSIBLE: 'Beitritt zum Spielraum nicht möglich!',

    ERR_GAME__REMOVE_PLAYER_FAILED: 'Spieler konnte nicht entfernt werden!',
    ERR_GAME__CALC_FAILED: 'Runde konnte aufgrund eines Fehlers nicht verarbeitet werden!',

    //ERR GAMEPLAY
    ERR_GAMEPLAY__ACT_MOVE_CARDS_FAILED: 'Du kannst jetzt diese Karte nicht legen!',
    ERR_GAMEPLAY__ACT_MOVE_CARDS_FAILED_WRONG: 'Du kannst diese Karte nicht auf die aktuelle legen!',
    ERR_GAMEPLAY__ACT_MOVE_CARDS_FAILED__NEED_TO_PULL: 'Zieh zuerst deine Karten!',
    ERR_GAMEPLAY__ACT_MOVE_CARDS_FAILED__NOT_YOUR_TURN: 'Du bist nicht dran!',
    ERR_GAMEPLAY__ACT_PULL_CARDS_FAILED: 'Du kannst jetzt keine Karte ziehen!',
    ERR_GAMEPLAY__ACT_CHANGE_CARDS_FAILED: 'Tauschen der Karten fehlgeschlagen',
    ERR_GAMEPLAY__ACT_CHANGE_CARDS_FAILED__NOT_ALLOWED: 'Du kannst nicht mit diesem Spieler tauschen',
    ERR_GAMEPLAY__ACT_CHANGE_CARDS_FAILED__NOT_YOUR_TURN: 'Zuerst muss der andere Spieler seine Karten tauschen!',
    ERR_GAMEPLAY__ACT_FIRST_PULL_FAILED: 'Aufdecken der ersten Karte hat nicht geklappt!',
    ERR_GAMEPLAY__ACT_LAST_CARD_FAILED: 'Melden der letzten Karte hat nicht geklappt!',
    ERR_GAMEPLAY__ACT_WISH_ICON_FAILED: 'Wünschen eines Symbols ist fehlgeschlagen',
    ERR_GAMEPLAY__ACT_CANNOT_FAILED: 'Melden von "Du kannst nicht" ist fehlgeschlagen',
    ERR_GAMEPLAY__ACT_CANNOT_FAILED__NEED_TO_PULL_ONE: 'Ziehe zuerst eine Karte bevor du nicht kannst!',
    ERR_GAME__ACT_SET_STATE_FAILED: 'Änderung des Status fehlgeschlagen',

    ERR__ACTBLOCK__NEED_TO_CHANGE: 'Es müssen zuerst die Karten getauscht werden!',
    ERR__ACTBLOCK__NEED_TO_WISH: 'Es muss zuerst etwas gewünscht werden!',

    ERR_PLAYER__LOAD_PLAYER_FAILED: 'Spielerprofil konnte nicht geladen werden!',
    ERR_LOAD_GAMES_FAILED: 'Spielrunden konnte nicht geladen werden',
    ERR_LOAD_FAILED: 'Inhalt konnte nicht geladen werden!',
    LOAD_MORE: 'Mehr laden...',
    LOADING_FAILED: 'Laden fehlgeschlagen!',
    DATA_NOT_ABLE_TO_LOAD: 'Daten konnte nicht geladen werden.',
    
    ERR_AUTH__REFRESH_TOKEN_FAILED_NO_DATA: 'Authentifizierung fehlgeschlagen. Keine Daten!',
    ERR_AUTH__REFRESH_TOKEN_FAILED: 'Authentifizierung fehlgeschlagen.',


    //GAME
    PLAY: 'Spielen', SELECT_GAMEROOM: 'Spielraum wählen...',
    CREATE_GAMEROOM: 'Spielraum erstellen',
    PRIVATE_ROOM: 'Privater Raum',
    PRIVATE_ROOM_NEED_PASSCODE: 'Privater Raum. PassCode erforderlich!',
    PUBLIC_ROOM: 'Öffentlicher Raum', GAME_QUICKGAME: 'Schnelles Spiel',
    GAME_REACHSCORE: 'Punktespiel',
    GAME_REACHSCORE_DESC: 'Spiel endet nach erreichen des Punktelimits.',
    GAME_QUICKGAME_DESC: 'Endloses Spiel ohne Punktelimit.',
    GAME_RANKEDGAME: 'Ranglistenspiel',
    GAME_CREATE_CHECKPRIVATE: 'Privater geschlossener Spielraum',
    GAME_OPEN: 'OFFEN',
    GAME_RUNNING: 'LÄUFT',
    GAME_FINISH: 'BEENDET',
    UNKNOWN: 'Unbekannt',

    GAME_ROOM_DESC_DOTS: 'Raumbeschreibung...',
    GAME_ROOM_NAME_DOTS: 'Raumname...',
    GAME_PASSCODE_DOTS: 'Passcode (4-Zahlen) eingeben...',
    GAME_PASSCODE: 'Passcode',
    GAME_SELECT_GAMEMODE_DOTS: 'Spielmodus wählen...',
    GAME_SELECT_PRIVAT_PUBLIC: 'Öffentlich oder Privat Spiel',
    GAME_SELECT_PLAYERS_DOTS: 'Spieleranzahl wählen...',
    GAME_SELECT_POINTS_DOTS: 'Punktzahl wählen...',
    JOINING_ROOM_DOTS: 'Raum beitreten...',
    ENTRY_PASSCODE: 'PassCode eingeben',
    GAME_PASSCODE_WRONG: 'PassCode falsch!',
    GAME_CREATEROOM_IN_PROGRESS: 'Spielraum wird erstellt...',
    GAME_SELECT_LOADING_ROOMS_DOTS: 'Lade Spielräume...',
    GAME_SELECT_REFRESH_ROOMS: 'Liste der Spielräume aktualisieren...',
    GAME_SELECT_LOADING_ROOMS_INFO: 'Alle Verfügbaren Spielräume werden geladen',
    GAME_SELECT_NO_ROOMS_FOUND: 'Keine Spielräume gefunden.',
    GAME_SELECT_CREATE_A_ROOM: 'Erstelle einen Spielraum',
    GAME_LEAVE_ROOM_QST: 'Spielraum verlassen?',
    GAME_LEAVE_ROOM_DESC: 'Möchtest du wirklich den Spielraum verlassen?',
    GAME_ROOM__LEAVED: 'Spielraum wurde verlassen',
    GAME_ROOM__LEAVED_BY_CODE: 'Verbindung zum Spielraum wurde unterbrochen!',

    GAME_LOADING__GAME_IS_LOADING: 'Spiel wird geladen...',
    GAME__SEARCH_CARDS: 'Karten suchen',
    GAME__CLEAN_TABLE: 'Tisch aufräumen',
    GAME__MIX_CARDS: 'Karten mischen',
    GAME__HAND_OUT_CARDS: 'Karten austeilen',
    GAME__GAME_PREPARING: 'Spiel vorbereiten',
    GAME_FINISHED: 'Runde beendet',
    GAME_FINISH_CALC_GAME: 'Spiel auswerten',
    GAME_FINISH_CALC_PLEASE_WAIT: 'Warte bis die Runde ausgewertet wurde...',
    GAME__PASSCODE_REQUIRED: 'Passcode erforderlich!',
    GAME__ENTRY_PASSCODE_TO_JOIN: 'Passcode eingeben zum beizutreten',

    GAME_CANCELED: 'Spiel wird abgebrochen!',
    GAME_CANCELED_DESC: 'Die Anzahl der aktiven Spieler reicht nicht aus.',
    GAME_WAIT_FOR_PLAYER: 'Warte auf Spieler',
    KICK_PLAYER_FROM_ROOM: 'Spieler rauswerfen',
    REMOVE_PLAYER: 'Spieler entfernen',
    REMOVE_PLAYER_SUBTITLE: 'Diesen Spieler wirklich aus dem Spielraum werfen?',
    PLAYER_KICKED_FROMROOM: 'Spieler wurde aus dem Raum geworfen!',
    GAME_VIEWERMODE_INFO_NEXT_ROUND: 'Ab der nächsten Runde kannst du wieder am Spiel teilnehmen.',
    GAME_VIEWERMODE: 'Zuschauermodus',

    //States
    PLAYER_LEAVE: 'Verlässt Raum..',
    PLAYER_LEAVED: 'Raum verlassen',
    PLAYER_DELAYED: 'Verbindung unterbrochen',
    PLAYER_JOINED: 'Beigetreten',
    PLAYER_IGNORE: 'Nicht aktiv',
    PLAYER_AFK: 'Nicht anwesend',
    PLAYER_FORCE_LOGIN: 'Verbindet sich erneut',
    PLAYER_WISH: 'Wünscht',
    PLAYER_ONCHANGE: 'Tauscht Karten',
    PLAYER_EXPOSE: 'Setzt aus',
    PLAYER_CHANGEROOM: 'Wechselt den Raum',
    PLAYER_CURRENT: 'Ist dran',

    //Methods
    GAME_TO_PLAY_DROP_CARD: 'Zum Spielen, Karte ablegen',
    GAME_TO_PLAY_DROP_CARD_HERE: 'Zum Spielen, Karte hier ablegen',

    GAME_CANNOT: 'Kann nicht!',
    GAME_LAST_CARD: 'Mau!',
    GAME__CHANGE_CARDS: 'Tausche deine Karten!',
    GAME__KEEP_CARDS: 'Karten behalten...',
    GAME__YOU_NEED_TO_CHANGE_SHORT: 'Du musst deine Karten tauschen!',
    GAME__YOU_NEED_TO_CHANGE: 'Tausche deine Karten mit einem anderen Spieler. Klicke hierfür auf ein Spielerprofil!',
    GAME__YOU_NEED_TO_CHANGE_INFO: 'Lege eine weitere Herzdame, um deine Karten behalten zu können.',
    GAME__WISH_SYMBOL: 'Symbol wünschen',
    GAME__YOU_NEED_TO_WISH: 'Wünsch dir ein Symbol',
    GAME__YOUR_TURN: 'Du bist dran!',
    GAME__SELECT_A_PLAYER_DOTS: 'Wähle einen Spieler...',
    GAME__NO_CHANGE_CARDS: 'Karten behalten', 
    GAME__YOU_EXPOSE: 'Du musst aussetzen!', 
    GAME__NOT_SAY_LAST: 'Du hast nicht Mau gesagt!, es wurde eine Karte für dich gezogen.', 

    GAME__YOU_FIRST_PULL: 'Du musst die erste Karte aufdecken!',
    GAME__WELCOME_IN_GAME: 'Willkommen im Spiel!',
    GAME_DIRECTION_CHANGED: 'Richtung gewechselt',
    GAME__SOLVED_BETWIXT_NOW: 'Runde beendet, Jetzt zwischenwerden!',

    HEART: 'Herz',
    SPRADES: 'Pik',
    CROSS: 'Kreuz',
    CHECKERED: 'Karo',
    RETRY: 'Nochmal',

    LAST_CARD_HINT: 'ist Mau!',
    CARDS: 'Karten',
    CARD: 'Karte',

    GAME_FINISH__SUMMARY: 'Zusammenfassung',
    GAME_FINISH__WINNERS: 'Gewonnen hat...',
    GAME_FINISH__NO_WINNER: 'Die Runde wurde vor dem Ende abgebrochen!',
    GAME_FINISH__DURATION: 'Dauer',
    GAME_FINISH__JACKFINISH: 'Runde wurde mit Bube beendet!',
    GAME_FINISH__JACKFINISH_SUB: 'Punkte zählen doppelt!',
    GAME_FINISH__PLAYED_CARDS: 'Karten gepielt',
    GAME_FINISH__PLAYED_TURNS: 'Züge',
    GAME_FINISH__BETWIXT_MOVES: 'Zwischenwürfe',
    GAME_FINISH__PULLED_CARDS: 'Gezogen',
    GAME_FINISH__JACKONJACK: 'Bube auf Bube gelegt',
    GAME_FINISH__EXPOSE: 'Ausgesetzt',
    GAME_FINISH__RETURNFINISHER: 'Spieler zurückgeholt',
    GAME_FINISH__DOUBLETHROW: 'Doppelte Karte gelegt',
    GAME_FINISH__ASSCOMBI: 'Mit Ass kombiniert',
    GAME_FINISH__INTERRUPT: '7er Serie blockiert',
    GAME_FINISH__CANNOT: 'Nicht können',
    GAME_FINISH__WISH_NO_CAN: 'Gewünscht, niemand kann',
    GAME_FINISH__STACKS: 'Karten gemischt',
    GAME_FINISH__NOTMARKED: 'Mau vergessen',
    GAME_FINISH__PULLED_PLAYED_QUOTE: 'Quote (Gespielt/Gezogen)',
    GAME_FINISH__BEST_MOVE_FROM: 'BestMove von',
    GAME_FINISH__PLAYER_SUMMARY: 'Deine Zusammenfassung',
    GAME_FINISH__YOU_WIN: 'Du hast gewonnen!',
    GAME_FINISH__BEST_MOVE: 'BestMove',
    GAME_FINISH__TABLE: 'Tabelle',
    GAME_FINISH__READY_FOR_NEXT: 'Bereit für die nächte Runde..',
    HIGHLIGHTS: 'Highlights',
    LEAVE_ROOM: 'Raum verlassen',
    NEXT_ROUND: 'Nächste Runde',
    GAME_FINISH__LAST_CARD: 'Letzte Karte',
    WINNER: 'Gewinner',
    GAME_FINISH__LAST_CARDS: 'Karten',
    SUMMARY_POINTS: 'Gesamt',
    GAME_FINISH__PLAYED_ROUNDS: 'Runden',
    GAME_FINISH__JACKFINISHS: 'Bube beendet',
    GAME_FINISH__WINNED_ROUNDS: 'Gewonnen',
    GAME_FINISH__FINISH_HATTRICK: 'Strike',
    GAME_FINISH__NO_CARDS: 'Hat alle Karten gespielt',
    GAME_FINISH__REACHSCRORE_FINSIHED: 'Punktespiel abgeschlossen!',
    GAME_FINISH__REACHSCRORE_COMPLETE_DURATION: 'Gesamte Spielzeit:',
    MOST_POINTS: 'Meist geschrieben',
    GAME_FINISH__ALLPOINTS: 'Alle Punkte',
    UPDATE: 'Update',
    CREATE: 'Erstellen',
    SEND_FEEDBACK: 'Feedback senden',
    MORE_DOTS: 'Mehr...',
    YOU_WRITE_DOTS: 'Du schreibst...',
    YOU_RECEIVE_DOTS: 'Du erhälst...',
    HIGHLIGHTS_OF_GAME: 'Highlights des Spiels...',
    GAME_INFO__GAME_OVERVIEW: 'Spielübersicht',
    GAME_INFO__PLAYER_OVERVIEW: 'Spielerübersicht',
    GAME_INFO__HIGHESTPOINTS_OF: 'Höchstpunkte von ',

    SHOW_PERSONAL_ROUNDSTATS:'Eigene Rundenstatistik anzeigen...',
    XP: 'Erfahrung',
    XP_POINTS: 'Erfahrungspunkte',
    LOADING_DATA: 'Daten werden geladen',
    PLAYER_INFO__SHOW_PROFILE: 'Profil anzeigen..',
    PLAYER_INFO__LOAD_PROFILE_DOTS: 'Profile laden..',
    PLAYER_INFO__PROFILE_IS_LOADING: 'Spielerprofil wird geladen',
    PLAYER_INFO__AVG_SCORE: 'Punkteschnitt',
    PLAYER_INFO__AVG_EXP: 'XP-Schnitt',
    PLAYER_INFO__WINS_LOSES: 'Gewonnen/Verloren',
    PLAYER_INFO_SHOW_MORE_STATS: 'Weitere Daten anzeigen...',
    PLAYER_INFO__WINS: 'Gewonnen',
    PLAYER_INFO__LOSES: 'Verloren',
    PLAYER_INFO__GAME_WINS: 'Spiele gewonnen',
    PLAYER_INFO__NO_STATS_DATA_AVAILABLE: 'Noch keine Statistiken verfügbar',
    UNTIL: 'Noch',
    FORCELOGIN_ROOM_INFO: 'Wiedereinstieg in den Spielraum läuft..',
    NO_ONE: 'Keiner',
    NO_ONE_ONCE: 'Keinem',
    MORE_GAME_DETAILS: 'Weitere Spieldetails...',

    GAME__PAUSED: 'Spiel pausiert!',
    LOGOUT_SUCCESS: 'Erfolgreich abgemeldet!',
    CONNECTION_LOST: 'Verbindung wurde unterbrochen...',
    RECONNECT_SUCCESS: 'Verbindung wurde wieder hergestellt!',


    PLAYEREXP_ONE_MOVE: 'Karte gespielt',
    PLAYEREXP_ASS_COMBI: 'Mit Ass kombiniert',
    PLAYEREXP_DOUBLE_THROW: 'Gleiche Karte doppelt gelegt',
    PLAYEREXP_SEVEN_START: '7er Serie gestartet',
    PLAYEREXP_MOVE_JACK: 'Bube gespielt',
    PLAYEREXP_MOVE_HEARTLADY: 'Herzdame gespielt',
    PLAYEREXP_BETWIXT_THROW: 'Zwischenwurf',
    PLAYEREXP_INTERRUPT_SEVEN: '7er Serie blockiert',
    PLAYEREXP_WISH_NO_CAN: 'Gewünscht, niemand kann',
    PLAYEREXP_RETURN_FINISH_PLAYER: 'Spieler zurückgeholt',
    PLAYEREXP_DOUBLE_JACK: 'Bube auf Bube gelegt',

    PLAYEREXP_KEEP_IGNORE_ON_ROUND_FINISHED: 'Nicht aktiv während Rundenende',
    PLAYEREXP_LEFT_ROUND_BEFORE_FINISHED: 'Runde vor dem Ende verlassen',
    PLAYEREXP_LEFT_GAME_BEFORE_FINISHED: 'Spiel vor dem Ende verlassen',
    PLAYEREXP_ROUND_EXP: 'Individuelle Erfahrung für diese Runde',

    PLAYEREXP_END_GAME_KING: 'Runde mit König beendet',
    PLAYEREXP_END_GAME_SEVEN_START: 'Runde mit einer 7er Serie beendet',
    PLAYEREXP_END_GAME_HEARTLADY: 'Runde mit Herzdame beendet',
    PLAYEREXP_END_GAME_BETWIXT: 'Runde durch Zwischenwurf beendet',
    PLAYEREXP_END_GAME_JACK: 'Runde mit Buben beendet',
    PLAYEREXP_BEST_MOVE: 'BestMove der Runde',
    PLAYEREXP_GAME_BEST_MOVE: 'BestMove des gesamten Spiels',
    PLAYEREXP_FINISH_GAME_ON_45_SEC: 'Spiel innerhalb von 45 Sekunden beendet',
    PLAYEREXP_FINISH_GAME_ON_1_MIN_4P: 'Spiel mit min. 4 Spieler innerhalb von 1 Minute beendet',
    PLAYEREXP_FINISH_GAME_ON_1_MOVE: 'Spiel mit dem ersten Zug beendet',
    PLAYEREXP_FIRST_WIN: 'Erstes Spiel gewonnen',
    PLAYEREXP_GAME_FINISH_HATTRICK: '3 Runden in folge gewonnen',
    PLAYEREXP_GAME_FINISH_3_ROUNDS_JACK: '3 Runden in folge mit Buben beendet',
    PLAYEREXP_GAME_WIN_5: '5 Runden in folge gewonnen',
    PLAYEREXP_GAME_WIN_10: '10 Runden in folge gewonnen',
    PLAYEREXP_GAME_WIN_15: '15 Runden in folge gewonnen',
    PLAYEREXP_GAME_PLAY_15: '15 Runden gespielt',
    PLAYEREXP_GAME_WIN_WHITOUT_WIN: 'Spiel ohne eine Runde zu gewinnen, gewonnen',
    PLAYEREXP_GAME_WIN_ALL_ROUNDS: 'Alle Runden gewonnen',
    PLAYEREXP_ROUND_WINNER_BONUS: 'Spielrunde gewonnen',
    PLAYEREXP_GAME_WIN: 'Spiel gewonnen',
    PLAYEREXP_GAME_PLAYED: 'Spiel gespielt',

    PLAYED_ROUND: 'Gespielte Runde',
    SHOW_ROUND: 'Runde anzeigen...',
    SHOW_GAME: 'Spiel anzeigen...',
    PLAYED_GAME: 'Gespielter Spielraum...',
    XP_OVERVIEW: 'Erfahrungsübersicht',

    PLAYEREXP_MOST_BETWIXT: 'Meiste Zwischenwürfe',
    PLAYEREXP_MOST_SEVENSTART: 'Am häufigsten 7er Serie gestartet',
    PLAYEREXP_MOST_RETURNFINISHER: 'Meisten Spieler zurückgeholt',
    HIGHLIGHT_MOST_INTERRUPT: '7er Serien blockiert',
    HIGHLIGHT_MOST_JACK_ON_JACK: 'Bube auf Bube gelegt',
    HIGHLIGHT_MOST_FINISH: 'Meiste Runden gewonnen',
    HIGHLIGHT_MOST_JACKFINISH: 'Runden mit Bube beendet',
    HIGHLIGHT_MOST_BADEST: 'Häufigsten die Höchsten Punkte geschrieben',

    PLAYEREXP_LIST_OF_ADDITIONAL: 'Zusätzliche Erfahrung erhalten durch...',
    PLAYER_VIEW_PLAYEREXP: 'Details zur erhaltenen Erfahrung anzeigen...',


    GAME_FINISH__TAKED_EXP: 'Erfahrung erhalten',
    GAME_FINISH__YOUWRITE1: 'Du schreibst',
    GAME_FINISH__YOUWRITE2: 'Punkte!',
    GAME_ROOM__CLOSING_SOON: 'Spielraum wird gleich geschlossen!',

    

    PLAYER_LEAVED_ROOM: (playerName: string) => (playerName == 'unknown' ? 'Ein Spieler' : playerName) + ' hat den Spielraum verlassen!',
    PLAYER_LOST_CONNECTION: (playerName: string) => (playerName == 'unknown' ? 'Ein Spieler' : playerName) + ' hat die Verbindung verloren!',
    PLAYER_JOINED_ROOM: (playerName: string) => (playerName == 'unknown' ? 'Ein Spieler' : playerName) + ' ist dem Spielraum beigetreten',
    PLAYER_RECONNECTED: (playerName: string) => (playerName == 'unknown' ? 'Ein Spieler' : playerName) + ' hat sich wieder verbunden..',
    PLAYER_IGNORE_RETURNED_BACK: (playerName: string) => (playerName == 'unknown' ? 'Ein Spieler' : playerName) + ' ist dem Spielraum wieder beigetreten.',
    PLAYER_KEEP_IGNORE: (playerName: string) => (playerName == 'unknown' ? 'Ein Spieler' : playerName) + ' wird weiterhin ignoriert!',
    PLAYER_IGNORE_AS_VIEWER: (playerName: string) => (playerName == 'unknown' ? 'Ein Spieler' : playerName) + ' ist wieder als Zuschauer beigetreten.',
    JOINING_ROOM_INFO: (roomName:string) => 'Beitritt zu ' + roomName + ' läuft',
    GAME__YOU_NEED_TO_PULL: (pull:number) => 'Du musst ' + pull + ' ' + (pull > 1 ? 'Karten' : 'Karte') + ' ziehen!',

    GAME_FINISH__LEVEL_UP: (level:number|undefined) => 'Du hast Level ' + level + ' erreicht!',
    GAME_FINISH__WRITE_POINTS: (points:number|undefined) => 'Du schreibst ' + points + ' Punkte!',
    GAME_FINISH__TAKE_EXP: (exp:number|undefined) => exp + ' Erfahrung erhalten',
    GAME_WAITING_PLAYER_RECONNECT: (playerName:string) => 'Es wird 45 Sekunden gewartet, ob sich '+playerName+' wieder verbindet.',
    GAME_FINISH__MOST_WRITED_BY: (points:number) => 'Meisten Punkte ('+points+') schreibt:',
    
    GAME_FINISH__MOST_BETWIXT: (points:number) => 'Meisten Zwischenwürfe ('+points+') von:',
    GAME_FINISH__MOST_RETURN_FINISHER: (points:number) => 'Spieler zurückgeholt ('+points+') hat:',
    GAME_FINISH__MOST_SEVENSTART: (points:number) => 'Meisten 7er Serien ('+points+') von:',

    
    GAME__SOLVED_BY_PLAYER: (playerName: string|undefined) => (playerName ? playerName : 'Spieler') + ' hat die Runde beendet!',
    GAME__PLAYERS_HAS_LAST_CARD: (playerName: string|undefined) =>  (playerName ? playerName : 'Spieler') + ' ist Mau!',
    GAME__PLAYERS_HAS_FINISH_CARD: (playerName: string|undefined) =>  (playerName ? playerName : 'Spieler') + ' ist Mau Mau!',
    GAME__BETWIXT_THROW: (playerName: string|undefined) => 'Zwischenwurf durch ' + (playerName ? playerName : 'Spieler') + '!',
    GAME__PLAYER_EXPOSED: (playerName: string|undefined) => (playerName ? playerName : 'Spieler') + ' muss aussetzen!',
    GAME__PLAYER_WISHS_ICON: (playerName: string|undefined) => ' wünscht sich ' + (playerName ? playerName : 'ein Spieler'),
    GAME__PLAYER_NEED_TO_WISH: (playerName: string|undefined) => (playerName ? playerName : 'Spieler') + ' muss sich etwas wünschen...',
    GAME__SEVEN_SERIES_ACTIVE: (playerName: string | undefined) =>  (playerName ? playerName : 'Spieler') + ' hat eine 7er Serie gestartet!',
    GAME__PLAYERS_CHANGED_CARDS: (p1: string | undefined, p2: string | undefined) =>  (p1 ? p1 : 'Spieler') + ' tauscht die Karten mit ' + (p2 ? p2 : 'einem anderen Spieler') + '!',
    GAME__PLAYER_DOES_NOT_CHANGE: (playerName: string | undefined) =>  (playerName ? playerName : 'Der Spieler') + ' behält seine Karten!',
    GAME__SEVEN_SERIES_ARE_INTERRUPTED: (playerName:string|undefined) => (playerName ? playerName : 'Spieler') + ' hat die 7er Serie geblockt!',
    GAME__SEVEN_SERIES_ACCEPT_BY_PLAYER: (playerName:string|undefined, cards: number |undefined) => (playerName ? playerName : 'Spieler') + ' kann nicht und muss ' + cards + ' ziehen!',
    GAME__PLAYER_NEED_TO_CHANGE: (playerName: string|undefined) => (playerName ? playerName : 'Spieler') + ' ' + (playerName && playerName.includes(',') ? 'tauschen Ihre' : 'tauscht die') +' Karten!',
    GAME__PLAYER_NEED_TO_CHANGE_FIRST: (playerName: string|undefined) => 'Zuerst muss ' + (playerName ? playerName : 'Spieler') + ' tauschen.',
    GAME__PLAY_7_10_OR_PULL_CARDS: (toPull: number|undefined) => 'Lege 7 oder 10! Sonst ziehe ' + toPull + ' Karten!',
    GAME__PLAYER_JACK_ON_JACK: (playerName: any) => playerName + ' hat Bube auf Bube gelegt!',
    GAME__PLAYER_FIRST_PULL: (playerName: string | any) => playerName + ' muss die erste Karte aufdecken...',
    GAME__PULLED_FOR_YOU: (cards: string | any) => 'Es ' + (cards != '1' ? 'wurden ' + cards + ' Karten' : 'wurde 1 Karte')  + ' für dich gezogen...',
    GAME__PLAYER_NOT_SAY_LAST: (playerName: string | any) => (playerName ? playerName : 'Spieler') + ' hat nicht Mau gesagt!',
    GAME__PLAYER_KEEP_CARDS: (playerName: string | any) => (playerName ? playerName : 'Spieler') + ' behält seine Karten...',
    GAME__PLAYER_RETURNED: (playerName: string | any, returnedPlayer: string | any) => (playerName ? playerName : 'Spieler') + ' hat ' + (returnedPlayer ? returnedPlayer : 'einen anderen Spieler') + ' zurück geholt!',
};
