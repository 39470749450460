import React, {} from 'react';
import { _Account } from 'services/api/types/Auth';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import appStore from 'store/app.store';
import { Control } from 'services/api/Control';
import controlStore from 'store/control.store';
import AppCircularProgress from '@crema/core/AppCircularProgress';
import { Brown, DarkBrown, Green, LightBrown, LightGreen, LightOrange, Orange, Red } from 'shared/constants/Colors';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

interface IProps {
}


  
const ControlMetricsHeader: React.FC<IProps> = (props) => {

  const [stop, setStop] = React.useState(false)
  const storeDispatch: AppDispatch = useDispatch();
  
  const history = useHistory();
  const storeLogged = useSelector((state: RootState) => state.session.logged);
  const {autoUpdate, cpu, usedMem, maxMem, connections, roomCount} = useSelector((state: RootState) => state.control);

    const percentMem = Math.floor(usedMem / (maxMem/100));
    let cpuCol = Green
    let memCol = Green
    

    const _goToDashboard = () => {
        history.replace('/dashboard')
    }

    

    if(cpu > 85) {
        cpuCol = Red
    }else if(cpu > 65) {
        cpuCol = Orange
    }else if(cpu > 50) {
        cpuCol = LightOrange
    }else if(cpu > 30) {
        cpuCol = LightGreen
    }

    if(percentMem > 85) {
        memCol = Red
    }else if(percentMem > 65) {
        memCol = Orange
    }else if(percentMem > 50) {
        memCol = LightOrange
    }else if(percentMem > 30) {
        memCol = LightGreen
    }

    return (<Box onClick={_goToDashboard} style={{cursor: 'pointer'}} display={'flex'} alignSelf={'center'} mt={1} mr={0} ml={2}>

{/*
            <Box display={'flex'} mr={2}>
                <AppCircularProgress activeColor={LightBrown} value={100} thickness={2} hidePercentage maxWidth={50} minWidth={50} centerNode={
                <Box style={{marginTop: 0, fontWeight: 'normal', fontSize: 11, textAlign: 'center'}}>
                    {connections}<br />
                    Client
                </Box>
                }/> 
            </Box>
            <Box display={'flex'} mr={5}>
                <AppCircularProgress activeColor={Brown} value={100} thickness={2} hidePercentage maxWidth={50} minWidth={50} centerNode={
                <Box style={{marginTop: 0, fontWeight: 'normal', fontSize: 11, textAlign: 'center'}}>
                    {roomCount}<br />
                    Room
                </Box>
                }/> 
            </Box>
            */}

            <AppCircularProgress activeColor={cpuCol} value={cpu} thickness={4} hidePercentage maxWidth={50} minWidth={50} centerNode={
            <Box style={{marginTop: 0, fontWeight: 'normal', fontSize: 10, textAlign: 'center'}}>
                {cpu}%<br />
                CPU
            </Box>
            }/> 
            <Box display={'flex'} ml={2}>
            <AppCircularProgress activeColor={memCol} value={percentMem} hidePercentage thickness={4} maxWidth={50} minWidth={50} centerNode={
                <Box style={{marginTop: 0, fontWeight: 'normal', fontSize: 10, textAlign: 'center'}}>
                    {Math.floor(usedMem)}<br />
                    MB
                </Box>
            } />
            </Box>
    </Box>);
    };

export default ControlMetricsHeader;
