import React from 'react';

export const accountsConfig = [
  {
    auth: ['admin'],
    routes: [
      {
        path: '/account/accountslist',
        component: React.lazy(() => import('./AccountsList')),
      },
    ],
  },
  {
    auth: ['admin'],
    routes: [
      {
        path: '/account/account/:id',
        component: React.lazy(() => import('./AccountEdit')),
      },
    ],
  },
  {
    auth: ['admin'],
    routes: [
      {
        path: '/account/account',
        component: React.lazy(() => import('./AccountCreate')),
      },
    ],
  },
];
