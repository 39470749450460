import React from 'react';

export const infosConfig = [
  {
    auth: ['admin'],
    routes: [
      {
        path: '/info/infoslist',
        component: React.lazy(() => import('./InfosList')),
      },
    ],
  },
  {
    auth: ['admin'],
    routes: [
      {
        path: '/info/info/:id',
        component: React.lazy(() => import('./InfoEdit')),
      },
    ],
  },
  {
    auth: ['admin'],
    routes: [
      {
        path: '/info/info',
        component: React.lazy(() => import('./InfoCreate')),
      },
    ],
  },
];
