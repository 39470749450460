
import {_PlayerComplete, _PlayerDetails, _PlayerExp, _PlayerExpsItem, _PlayerLastExp, _PlayersItem} from "./types/Player";
import { _Playerround } from "./types/Game";
import { Api } from "App";
import { Errors } from "data/const/Errors";
import { ErrorMsg } from "data/models/ErrorMsg";
import LogHelper from "helpers/LogHelper";
import { _Account, _AccountsAccount } from "./types/Auth";
import { _StatsSummary } from "./types/Stats";

export class Stats {


    /**
     * Delete Log
     * @param logId
     * @return Promise<boolean>
     */
    static summary(days: number): Promise<_StatsSummary | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
            const response = await Api.get('/admin/control/statsSummary?days=' + days, undefined, true);


                if (response && response.stats) {
                        //Parse JSON String "account"
                        let playerDetails = response.stats;
                        LogHelper.logInfo('Services/Api/Stats/summary', 'load summary success');
                        resolve(playerDetails);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Stats/summary', 'Delloadete summary failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Stats/summary', 'load summary failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }

    

}
