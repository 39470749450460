import React, {} from 'react';
import { _Account } from 'services/api/types/Auth';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import appStore from 'store/app.store';
import { Control } from 'services/api/Control';
import controlStore from 'store/control.store';
import { Stats } from 'services/api/Stats';

interface IProps {
}


  
const ControlMetricsLoader: React.FC<IProps> = (props) => {

  const [stop, setStop] = React.useState(false)
  const [loadedSum, setSum] = React.useState(false)
  const storeDispatch: AppDispatch = useDispatch();
  const storeLogged = useSelector((state: RootState) => state.session.logged);
  const storeEnv = useSelector((state: RootState) => state.app.env);
  const [env, setEnv] = React.useState(storeEnv)
  const {autoUpdate:storeAutoUpdate, reloadMetricsCode:storeReloadMetrics} = useSelector((state: RootState) => state.control);
  const storeStatsLoaded = useSelector((state: RootState) => state.control.stats.loaded);



  React.useEffect(() => {
    let interval:any;
    if(storeLogged) {
      loadMetrics();
      interval = setInterval(() => {
        loadMetrics();
      }, 15000);
    }
  
    return () => {
      if(interval) {
        clearInterval(interval);
      }
    }
  }, [storeLogged])

  React.useEffect(() => {
    if(storeLogged && storeReloadMetrics != 0) {
        loadMetrics(true);
    }
  }, [storeReloadMetrics])

  React.useEffect(() => {
    if(storeLogged && storeEnv != env) {
        loadMetrics(true);
    }
  }, [storeLogged])

  const loadMetrics = (force?:boolean) => {
    if(stop || !storeAutoUpdate) {
      return;
    }
    if(!loadedSum || force) {
      Stats.summary(30).then((r:any) => {
        if(r) {
          storeDispatch(controlStore.actions.setStats(r));
          setSum(true);
          setEnv(storeEnv);
        }else{
          storeDispatch(appStore.actions.setError('Stats konnten nicht geladen werden!'));
        }
      }).catch(e => {
        storeDispatch(controlStore.actions.clearStats());
        storeDispatch(appStore.actions.setError('Exception: Stats konnten nicht geladen werden!'));
      });
    }

    Control.metrics().then((r:any) => {
      if(r) {
        storeDispatch(controlStore.actions.setMetric(r));
      }else{
        storeDispatch(appStore.actions.setError('Control-Metrics konnten nicht geladen werden!'));
        setStop(true)
      }
    }).catch(e => {
      storeDispatch(appStore.actions.setError('Exception: Control-Metrics konnten nicht geladen werden!'));
      setStop(true)
    });

  }

    return (<></>);
};

export default ControlMetricsLoader;
