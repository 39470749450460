import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Api } from "App";
import { ErrorMsg } from "data/models/ErrorMsg";
import { ToastMsg } from "data/models/ToastMsg";
import ApiClient from "services/api/ApiClient";

export interface IAppStore  {
    env: string;
    uniqueId: string;
    deviceType: string;
    os: string;
    toast: ToastMsg|false;  
    navCollapsed: boolean,
    isAppDrawerOpen: boolean,
    initialPath: string|undefined,
    loading: boolean,
    error:any,
    message:any
  }

export const initialState: IAppStore = {
  env: 'PRODUCTION',
  uniqueId: '',
  deviceType: '',
  os: '',
  toast: false,
  navCollapsed: false,
  isAppDrawerOpen: false,
  initialPath: '/',
  loading:false,
  error: undefined,
  message: undefined
}

/*
 * ACTIONS
 */

export const appStoreAction = {};


/*
 * REDUCER
 */

const appStore = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    setToastMsg: (state, action: PayloadAction<ToastMsg>) => {
      state.toast = action.payload;
    },
    clearToast: (state, action: PayloadAction) => {
      state.toast = false;
    },
    setErrorToastMsg: (state, action: PayloadAction<ErrorMsg | any>) => {
      const toast = new ToastMsg(action.payload.error.userMessage, action.payload.error, 'error')
      state.toast = toast;
    },
    setPath: (state, action: PayloadAction<string|undefined>) => {
      state.initialPath = action.payload;
    },
    toggleNavCollapse: (state, action: PayloadAction) => {
      state.navCollapsed = !state.navCollapsed;
    },
    toggleAppDrawer: (state, action: PayloadAction) => {
      state.isAppDrawerOpen = !state.isAppDrawerOpen;
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    switchEnv: (state, action: PayloadAction<string>) => {
      state.env = action.payload;
      Api.changeEnv(state.env);
    },
    //sort: state => state.sort((a, b) => a.message.localeCompare(b.message))
  },
  extraReducers: (builder) => {
    /*builder.addCase(updateUser.rejected, (state, action) => {
      if (action.payload) {
        // Since we passed in `MyKnownError` to `rejectValue` in `updateUser`, the type information will be available here.
        state.error = action.payload.errorMessage
      } else {
        state.error = action.error
      }
    })*/
  },
});


export default appStore;
