import React from 'react';

export const playersConfig = [
  {
    auth: ['admin'],
    routes: [
      {
        path: '/player/players',
        component: React.lazy(() => import('./Playerslist')),
      },
    ],
  },
  {
    auth: ['admin'],
    routes: [
      {
        path: '/player/player/:id',
        component: React.lazy(() => import('./PlayerDetails')),
      },
    ],
  }
];
