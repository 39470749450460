



export const isCacheExpired = (time:number, expired_in: number) => {

    if(time == 0) {
        return true;
    }

    const now = new Date().getTime();

    if((now-time) > (expired_in*1000)) {
        return true;
    }
    return false;

}