import React from 'react';

export const gameConfig = [
  {
    auth: ['admin'],
    routes: [
      {
        path: '/game/games',
        component: React.lazy(() => import('./Gameslist')),
      },
    ],
  },
  {
    auth: ['admin'],
    routes: [
      {
        path: '/game/game/:id',
        component: React.lazy(() => import('./GameDetails')),
      },
    ],
  },
  {
    auth: ['admin'],
    routes: [
      {
        path: '/game/round/:id',
        component: React.lazy(() => import('./RoundDetails')),
      },
    ],
  }
];
