

export default class LangHelper {

    static e(langText: LangHelper): string {

        let string = '*** LANG_NOT_FOUND: ' + langText + '***';

        string = langText.toString();

        return string;
    }

}
