import React from 'react';

export const controlConfig = [
  {
    auth: ['admin'],
    routes: [
      {
        path: '/control/room/:id',
        component: React.lazy(() => import('./RoomDetails')),
      },
    ],
  },
  {
    auth: ['admin'],
    routes: [
      {
        path: '/control/onlineplayers',
        component: React.lazy(() => import('./OnlinePlayers')),
      },
    ],
  }
];
