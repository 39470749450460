import { ErrorObj } from "data/const/Errors";
import { formattedTimeStamp } from "helpers/Tools";

export class ToastMsg {
    date: string;
    valid: boolean = true;
    error?: ErrorObj;
    message?: string;
    type?: 'success'|'warning'|'error'|'info'|'default';

    constructor(message?:any, error?: ErrorObj, type?: 'success'|'warning'|'error'|'info'|'default'){

        if(!message && !error) {
            this.valid = false;
        }

        this.date = formattedTimeStamp();
        this.error = error;
        this.message = message;
        this.type = type ? type : error ? 'error' : 'default';
    }
}
