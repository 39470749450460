import React from 'react';
import {Redirect} from 'react-router-dom';

import {createRoutes} from '../@crema/utility/Utils';
import {accountsConfig} from './account';
import {errorPagesConfigs} from './errorPages';
import {authRouteConfig} from './auth';
import {initialUrl} from '../shared/constants/AppConst';
import { dashRouteConfig } from './dashboard';
import { controlConfig } from './control';
import { playersConfig } from './player';
import { gameConfig } from './games';
import { infosConfig } from './info';
import { abuseRouteConfig } from './abuse';
import { requestRouteConfig } from './request';

const routeConfigs = [
  ...accountsConfig,
  ...errorPagesConfigs,
  ...authRouteConfig,
  ...abuseRouteConfig,
  ...requestRouteConfig,
  ...dashRouteConfig,
  ...controlConfig,
  ...playersConfig,
  ...gameConfig,
  ...infosConfig
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />,
  },
];

export default routes;
