
import {_PlayerComplete, _PlayerDetails, _PlayerExp, _PlayerExpsItem, _PlayerLastExp, _PlayersItem} from "./types/Player";
import { _Playerround } from "./types/Game";
import { Api } from "App";
import { Errors } from "data/const/Errors";
import { ErrorMsg } from "data/models/ErrorMsg";
import LogHelper from "helpers/LogHelper";

export class Player {


    /**
     * PlayerDetails of User
     * @param playerId
     * @return Promise<boolean>
     */
    static  playerDetails(playerId: string): Promise<_PlayerDetails | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
            const response = await Api.get('/admin/player/playerdetail/' + playerId, undefined, true);


                if (response && response.player) {
                        //Parse JSON String "account"
                        let playerDetails = response.player;
                        LogHelper.logInfo('Services/Api/Player/playerDetails', 'Load player success');
                        resolve(playerDetails);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Player/playerDetails', 'Load player failed', response);
                Api.error = new ErrorMsg(Errors.API_PLAYER__LOAD_PLAYER_FAILED);
                reject(Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Player/playerDetails', 'Load player failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API_PLAYER__LOAD_PLAYER_FAILED_BY_EXC, e);
                reject(Api.error);
                return;
            }
        });

    }


    /**
     * PlayerComplete of Player
     * @param playerId
     * @return Promise<boolean>
     */
    static playerComplete(playerId: string): Promise<_PlayerComplete | false> {


        return new Promise(async (resolve: any, reject:any) => {

            try {
            const response = await Api.get('/admin/player/playercomplete/' + playerId, undefined, true);

                if (response && response['player']) {

                    //Parse JSON String "account"
                    let playerDetails = response['player'];

                    LogHelper.logInfo('Services/Api/Player/playerComplete', 'Load player success');
                    resolve(playerDetails);
                    return;
                }
                //Login failed
                LogHelper.logError('Services/Api/Player/playerComplete', 'Load player failed', response);
                Api.error = new ErrorMsg(Errors.API_PLAYER__LOAD_PLAYER_FAILED);
                reject(Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Player/playerComplete', 'Load player failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API_PLAYER__LOAD_PLAYER_FAILED_BY_EXC, e);
                reject(Api.error);
                return;
            }

        });

    }
    
    
    /**
     * GameRounds of Player
     * @param playerId
     * @return Promise<boolean>
     */
    static player_games(playerId: string, sort?:string, desc?:boolean, skip?:number, limit?:number): Promise<_PlayerComplete | false> {

        let qu = [];
        if(sort) qu.push('sort='+sort)
        if(desc) qu.push('desc=true')
        if(skip) qu.push('skip='+skip)
        if(limit) qu.push('limit='+limit)

        let query = '?' + qu.join('&');

        return new Promise(async (resolve: any, reject:any) => {
            try {                   
            const response = await Api.get('/admin/player/games/' + playerId + query, undefined, true);
                if (response && response['games']) {
                        //Parse JSON String "account"
                        let games = response['games'];
                        LogHelper.logInfo('Services/Api/Player/player_games', 'Load player games success');
                        resolve(games);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Player/player_games', 'Load player games failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_GAMES_FAILED);
                reject(Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Player/player_games', 'Load player games failed by exception: ', e);
                Api.error = new ErrorMsg(Errors.API__LOAD_GAMES_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }
    

    /**
     * Round
     * @param roundId
     * @return Promise<boolean>
     */
     static playerround(playerId:string, roundId: number): Promise<_Playerround | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
                const response = await Api.get('/admin/player/' + playerId + '/playerround/' + roundId, undefined, true);


                if (response && response['playerround']) {
                        //Parse JSON String "account"
                        LogHelper.logInfo('Services/Api/Player/playerround', 'Load playerround success');
                        resolve(response['playerround']);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Player/round', 'Load playerround failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Player/round', 'Load playerround failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }
    
    /**
     * Round
     * @param roundId
     * @return Promise<boolean>
     */
     static players(skip: number, search?:string, sort?:string, desc?:boolean, count?:boolean): Promise<_PlayersItem | false> {
         
        let qu = [];
        if(sort) qu.push('sort='+sort)
        if(desc) qu.push('desc=true')
        if(count) qu.push('count=true')
        if(skip) qu.push('skip='+skip)

        let query = '?' + qu.join('&');
        
        return new Promise(async (resolve: any, reject:any) => {

            try {

                const response = search ? await Api.get('/admin/player/players/' + search + query, undefined, true) : await Api.get('/admin/player/players' + query, undefined, true);


                if (response && response['players']) {
                        //Parse JSON String "account"
                        LogHelper.logInfo('Services/Api/Player/players', 'Load players success');
                        resolve(response['players']);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Player/players', 'Load players failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Player/players', 'Load players failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }
    
    /**
     * Round
     * @param roundId
     * @return Promise<boolean>
     */
     static profile_lastexps(playerId:string): Promise<_PlayerLastExp | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
                const response = await Api.get('/admin/player/playerlastexps/' + playerId, undefined, true);


                if (response && response['exp']) {
                        //Parse JSON String "account"
                        LogHelper.logInfo('Services/Api/Player/profile_lastexps', 'Load profile_lastexps success');
                        resolve(response['exp']);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Player/profile_lastexps', 'Load profile_lastexps failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Player/profile_lastexps', 'Load profile_lastexps failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }
    

    /**
     * Round
     * @param roundId
     * @return Promise<boolean>
     */
     static playerexp(playerId:string, type:string, actionId: number): Promise<_PlayerExp | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
                const response = await Api.get('/admin/player/' + playerId + '/playerexp/' + type + '/' + actionId, undefined, true);


                if (response && response['exp']) {
                        //Parse JSON String "account"
                        LogHelper.logInfo('Services/Api/Player/playerround', 'Load playerexp success');
                        resolve(response['exp']);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Player/round', 'Load playerexp failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Player/round', 'Load playerexp failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }

    
      /**
     * Round
     * @param roundId
     * @return Promise<boolean>
     */
       static profile_exps(playerId:string, skip: number): Promise<_Playerround | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
                const response = await Api.get('/admin/player/playerexps/' + playerId + '?desc=true&skip=' + skip, undefined, true);


                if (response && response['exps']) {
                        //Parse JSON String "account"
                        LogHelper.logInfo('Services/Api/Player/profile_exps', 'Load profile_exps success');
                        resolve(response['exps']);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Player/profile_exps', 'Load profile_exps failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Player/profile_exps', 'Load profile_exps failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }
    
    /**
     * GameRounds of Player
     * @param playerId
     * @return Promise<boolean>
     */
    static playersMeta(playerIds: string): Promise<_PlayerComplete | false> {


        return new Promise(async (resolve: any, reject:any) => {
            try {                   
            const response = await Api.get('/admin/player/playersmeta/' + playerIds, undefined, true);
                if (response && response['players']) {
                        //Parse JSON String "account"
                        let players = response['players'];
                        LogHelper.logInfo('Services/Api/Player/playersMeta', 'Load playersMeta success');
                        resolve(players);
                        return;
                }
                //Login failed
                LogHelper.logError('Services/Api/Player/player_games', 'Load playersMeta failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Player/playersMeta', 'Load playersmeta failed by exception: ', e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }

        });

    }



    /**
     * Account of User
     * @param accountId
     * @return Promise<boolean>
     */
     static playerDelete(accountId: number): Promise<true | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
            const response = await Api.delete('/admin/player/player/' + accountId, undefined, true);


                if (response && response.player) {
                        //Parse JSON String "account"
                        let playerDetails = response.player;
                        LogHelper.logInfo('Services/Api/Player/playerDelete', 'Delete player success');
                        resolve(playerDetails);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Player/playerDelete', 'Delete player failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Player/playerDelete', 'Delete player failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }


    /**
     * Account of User
     * @param accountId
     * @return Promise<boolean>
     */
     static playerAvatarDelete(playerid: number): Promise<true | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
            const response = await Api.delete('/admin/player/avatardelete/' + playerid, undefined, true);


                if (response && response.player) {
                        //Parse JSON String "account"
                        let playerDetails = response.player;
                        LogHelper.logInfo('Services/Api/Player/avatardelete', 'Delete avatardelete success');
                        resolve(playerDetails);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Player/avatardelete', 'Delete avatardelete failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Player/avatardelete', 'Delete avatardelete failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }




    /**
     * Account of User
     * @param accountId
     * @return Promise<boolean>
     */
     static playerUpdate(playerId: number, data:any): Promise<_PlayerDetails | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
            const response = await Api.post('/admin/player/player/' + playerId, data, true);


                if (response && response.player) {
                        //Parse JSON String "account"
                        let playerDetails = response.player;
                        LogHelper.logInfo('Services/Api/Player/playerUpdate', 'update player success');
                        resolve(playerDetails);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Player/playerUpdate', 'update player failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(response ?? Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Player/playerUpdate', 'update player failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }

}
