
import { Api } from "App";
import Config from "config/Config";
import { Errors } from "data/const/Errors";
import { ErrorMsg } from "data/models/ErrorMsg";
import LogHelper from "helpers/LogHelper";
import SecureHelper from "helpers/SecureHelper";
import { delay } from "helpers/Tools";
import sessionStore from "store/session.store";
import { store } from "store/store";
import { Account } from "./Account";

export default class ApiClient {

    env: string = 'PRODUCTION';
    apiUrlDev!: string;
    apiUrlStaging!: string;
    apiUrlProduction!: string;
    srvUrlDev!: string;
    srvUrlStaging!: string;
    srvUrlProduction!: string;
    apiKey1!: string;
    apiKey2!: string;
    token!: string;
    apiToken!: string;
    uniqueId!: string;
    controlToken!:string;
    metricToken!:string;
    metricTokenProduction!:string;
    userData!: any;

    refreshing: boolean = false;
    error: ErrorMsg|any = false;
    errorClient: ErrorMsg|any = false;

    constructor() {
       this.apiUrlDev = Config.api.apiUrlDev;
       this.apiUrlStaging = Config.api.apiUrlStaging;
       this.apiUrlProduction = Config.api.apiUrlProduction;
       this.srvUrlDev = Config.api.srvUrlDev;
       this.srvUrlStaging = Config.api.srvUrlStaging;
       this.srvUrlProduction = Config.api.srvUrlProduction;
       this.apiKey1 = Config.api.apiKey1;
       this.apiKey2 = Config.api.apiKey2;
       this.uniqueId = 'unknown';
       this.metricToken = Config.api.srvMetricToken
       this.metricTokenProduction = Config.api.srvMetricTokenProduction
    }

    changeEnv(env:any|'DEV'|'STAGING'|'PRODUCTION') {
        this.env = env;
    }

    /**
     * Setzt den AuthToken für ApiRequests
     * @param token
     */
    setAuthToken(token:string) {
        this.token = token;
    }


    /**
     * Setzt den AuthToken für ApiRequests
     * @param token
     */
    setControlToken(token:string) {
        this.controlToken = token;
    }


    /**
     * Setzt den AuthToken für ApiRequests
     * @param token
     */
    setApiToken(token:string) {
        this.apiToken = token;
    }


    /**
     * GetAccess - Get ApiToken from Server
     * @param uniqueId
     */
    async getAccess(uniqueId:string|null = null):Promise<any | ErrorMsg> {

        try{
            if(uniqueId == null) {
                uniqueId = this.uniqueId;
            }

            LogHelper.logInfo('Services/Api/ApiClient/getaccess', 'Getting Access to API....');
            const response = await this.post('/core/getaccess',
                {
                    apiKey: SecureHelper.generateApiKey(this.apiKey1, this.apiKey2),
                    uniqueId: uniqueId,
                });


            if(response && response.apiToken) {
                LogHelper.logInfo('Services/Api/ApiClient/getaccess', 'Getting Access to API success');
                this.setApiToken(response.apiToken);
                return response;
            }else{
                LogHelper.logError('Services/Api/ApiClient/getaccess', 'Getting Access to API failed: response or apiToken not available: ' + JSON.stringify(response));
                this.error = new ErrorMsg(Errors.API_GETACCESS__FAILED_NO_TOKEN);
                return new ErrorMsg(Errors.API_GETACCESS__FAILED_NO_TOKEN);
            }

        }catch (e) {
            LogHelper.logError('Services/Api/ApiClient/getaccess', 'Getting Access to API failed hard: ', e);
            this.error = new ErrorMsg(Errors.API_GETACCESS__FAILED_BY_EXC, e);
            return new ErrorMsg(Errors.API_GETACCESS__FAILED_BY_EXC, e);
        }

    }



    /**
     * Get Request
     * @param url
     * @param body
     * @param headers
     */
    async getOnServer(url:string, body:any, saveApi: boolean = false, headers:any = [], only200?:boolean, onlyMetric?:boolean) {

        const host = this.env == 'DEV' ? this.srvUrlDev : this.env == 'STAGING' ? this.srvUrlStaging : this.srvUrlProduction;
        const metricToken = this.env == 'PRODUCTION' ? this.metricTokenProduction : this.metricToken;
        try{
            const response = await fetch(host + url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'mode': 'no-cors',
                    'Authorization': onlyMetric ? metricToken : this.controlToken,
                    ...headers,
                },
                body: JSON.stringify(body),
            });
            if(only200 && response.status == 200) {
                return true;
            }
            return await response.json();
        }catch (e) {
            LogHelper.logError('Services/Api/ApiClient/get', 'GET-Request failed: ', e);
            this.error = new ErrorMsg(Errors.API_GET__FAILED_BY_EXC, e);
            throw new Error(this.error);
        }

    }



    /**
     * GetAccess - Get ApiToken from Server
     * @param uniqueId
     */
     async refreshToken():Promise<Account | false | 'skipped'> {

        try{
            if(this.refreshing) {
                LogHelper.logInfo('Services/Api/ApiClient/refreshToken', 'Already refreshing token... wait 3 seconds');
                await delay(3000);

                if(this.userData) {
                    return this.userData
                }
                return 'skipped'
            }
            this.refreshing = true;
            LogHelper.logInfo('Services/Api/ApiClient/refreshToken', 'Refresh Token on API....');

            if(!this.userData) {
                this.refreshing = false;
                this.error = new ErrorMsg(Errors.API_REFRESHTOKEN__FAILED_NO_STOREAGE_DATA);
                store.dispatch(sessionStore.actions.setLogout());
                return false;
            }

            const response = await this.post('/auth/refresh',
                {
                    refreshToken: this.userData.refreshToken,
                }, false);


            if(response && response.token) {
                LogHelper.logInfo('Services/Api/ApiClient/refreshToken', 'refresh token success');
                this.setAuthToken(response.token);

                this.userData = response;

                this.refreshing = false;
                return this.userData
            }else{
                LogHelper.logError('Services/Api/ApiClient/refreshToken', 'refresh token failed: response or token not available: ' + JSON.stringify(response));
                this.error = new ErrorMsg(Errors.API_REFRESHTOKEN__FAILED);
                store.dispatch(sessionStore.actions.setLogout());
                this.refreshing = false;
                return false
            }

        }catch (e) {
            LogHelper.logError('Services/Api/ApiClient/refreshToken', 'refresh token failed hard: ', e);
            this.error = new ErrorMsg(Errors.API_REFRESHTOKEN__FAILED_BY_EXC, e);
            this.refreshing = false;
            store.dispatch(sessionStore.actions.setLogout());
            return false
        }

    }



    async checkResponseIsFailedAndRefresh(response: any, retry: (t:any) => Promise<any>) {
        if(response && (response?.status == 401 || response?.status == 403) && !this.refreshing) {
            const resp = response?.bodyUsed ? response.body : await response.json();
            if(resp.error) {
                LogHelper.logError('Services/Api/ApiClient/checkResponseIsFailedAndRefresh', 'Request token can be expired, try to refreshing');

                const acc = await this.refreshToken();
                if(acc) {
                    response = await retry(this.token);
                }else{
                    store.dispatch(sessionStore.actions.setLogout());
                }
                return response;
            }
        }
        return response;
    }


    /**
     * Get Request
     * @param url
     * @param body
     * @param headers
     */
    async get(url:string, body:any, saveApi: boolean = false, headers:any = [], only200?: boolean) {

        let refreshed = false;
        const host = this.env == 'DEV' ? this.apiUrlDev :  this.env == 'STAGING' ? this.apiUrlStaging : this.apiUrlProduction;
        try{
            let timeout;
            const runFetch = async (token?:any) => new Promise<false|Response>((resolve, reject) =>
            {
                fetch(host + url, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': saveApi ? (token ?? this.token) : this.apiToken,
                        'Access-UniqueId': !saveApi ? this.uniqueId : '',
                        ...headers,
                    },
                }).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    LogHelper.logInfo('Services/Api/ApiClient/post', 'GET-Request failed: ' + err );
                    Api.error = new ErrorMsg(Errors.API__REQUEST_FAILED_BY_EXC, err);
                    reject(false);
                });
                timeout = setTimeout(reject.bind(null, Errors.API__TIMEOUT_ERROR), Config.api.timeout);
            });


            let response = await runFetch();
            if(timeout) clearTimeout(timeout)

            if(response) {

                //CHeck response token failure
                response = await this.checkResponseIsFailedAndRefresh(response, runFetch);

                if(response && only200 && response.status == 200) {
                    return true;
                }

                if(response && response.status != 200) {
                    LogHelper.logInfo('Services/Api/ApiClient/get', 'GET-Request not 200 : ' + url + ' - ' + response.status);
                }

                if(response && !response.bodyUsed) {
                    return await response.json();
                }else if(response) {
                    return response.body
                }

                this.error = new ErrorMsg(Errors.API_GET__FAILED_BY_EXC, response);
                throw new Error(this.error);
            }
        }catch (e) {
            LogHelper.logError('Services/Api/ApiClient/get', 'GET-Request failed: ', e);
            this.error = new ErrorMsg(Errors.API_GET__FAILED_BY_EXC, e);
            throw new Error(this.error);
        }

    }


    /**
     * POST Request
     * @param url
     * @param body
     * @param saveApi
     * @param headers
     */
    async post(url:string, body:any, saveApi: boolean = false, headers:any = []) {

        let refreshed = false;

        const host = this.env == 'DEV' ? this.apiUrlDev :  this.env == 'STAGING' ? this.apiUrlStaging : this.apiUrlProduction;
        try{
            let timeout
            const runFetch = async (token?:any) => new Promise<false|Response>((resolve, reject) =>
            {
                fetch(host + url, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': saveApi ? (token ?? this.token) : this.apiToken,
                        'Access-UniqueId': !saveApi ? this.uniqueId : '',
                        ...headers,
                    },
                    body: JSON.stringify(body),
                }).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    LogHelper.logInfo('Services/Api/ApiClient/post', 'POST-Request failed: ' + err );
                    Api.error = new ErrorMsg(Errors.API__REQUEST_FAILED_BY_EXC, err);
                    reject(err);
                });
                timeout = setTimeout(reject.bind(null, Errors.API__TIMEOUT_ERROR), Config.api.timeout);
            });

            let response = await runFetch();
            if(timeout) clearTimeout(timeout)

            //If Status Response HTTP 200 OK
            if (response) {
                //CHeck response token failure
                response = await this.checkResponseIsFailedAndRefresh(response, runFetch);

                if(response && response.status != 200) {
                    LogHelper.logInfo('Services/Api/ApiClient/post', 'POST-Request not 200: ' + url + ' - ' + response.status);
                }

                if(response && !response.bodyUsed) {
                    return await response.json();
                }else if(response) {
                    return response.body
                }
                LogHelper.logError('Services/Api/ApiClient/post', 'POST-Response no response');

                this.error = new ErrorMsg(Errors.API_POST__FAILED, response);
                throw new Error(this.error);
            } else {
                LogHelper.logError('Services/Api/ApiClient/post', 'POST-Response failed: ' + Api.error.error + ' ');
                this.error = new ErrorMsg(Errors.API_POST__FAILED, response);
                throw new Error(this.error);
            }

        }catch (e) {
            LogHelper.logError('Services/Api/ApiClient/post', 'POST-Request failed by exception: ', e);
            this.error = new ErrorMsg(Errors.API_POST__FAILED_BY_EXC, e);
            throw new Error(this.error);
        }
    }


    /**
     * Get Request
     * @param url
     * @param body
     * @param headers
     */
     async delete(url:string, body:any, saveApi: boolean = false, headers:any = []) {

        const host = this.env == 'DEV' ? this.apiUrlDev :  this.env == 'STAGING' ? this.apiUrlStaging : this.apiUrlProduction;
        try{
            const response = await fetch(host + url, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': saveApi ? this.token : this.apiToken,
                    'Access-UniqueId': saveApi ? this.uniqueId : '',
                    ...headers,
                },
                body: JSON.stringify(body),
            });
            return await response.json();
        }catch (e) {
            LogHelper.logError('Services/Api/ApiClient/get', 'DELETE-Request failed: ', e);
            this.error = new ErrorMsg(Errors.API__REQUEST_FAILED_BY_EXC, e);
            throw new Error(this.error);
        }

    }


}
