import React from 'react';
import {Loader, MessageView} from '../../../@crema';
import {useDispatch, useSelector} from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import appStore from 'store/app.store';

interface InfoViewProps {}

const InfoView: React.FC<InfoViewProps> = () => {

  const storeDispatch: AppDispatch = useDispatch();

  const {error, loading, message} = useSelector((state: RootState) => state.app);

  const afterShow = () => {
    storeDispatch(appStore.actions.setMessage(''))
  }
  const afterShowError = () => {
    storeDispatch(appStore.actions.setError(''))
  }
  const showMessage = () => {
    return <MessageView variant='success' afterShow={afterShow} message={message.toString()} />;
  };

  const showError = () => {
    return <MessageView variant='error'  afterShow={afterShowError}  message={error.toString()} />;
  };

  return (
    <>
      {loading && <Loader />}

      {message && showMessage()}
      {error && showError()}
    </>
  );
};

export default InfoView;
