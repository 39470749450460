export interface NavItemProps {
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: string[];
  children?: NavItemProps[] | NavItemProps;
}

const routesConfig: NavItemProps[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    messageId: 'sidebar.dashboard',
    type: 'item',
    url: '/dashboard',
    icon: 'insert_chart',
    auth: ['admin'],
  },
  {
    id: 'control',
    title: 'Serververwaltung',
    messageId: 'sidebar.control',
    type: 'collapse',
    icon: 'verified_user',
    auth: ['admin'],
    children: [
      {
        id: 'controlonlineplayers',
        title: 'Online Spieler',
        messageId: 'sidebar.control.onlineplayers',
        type: 'item',
        url: '/control/onlineplayers',
      }
    ],
  },
  {
    id: 'players',
    title: 'Spieler',
    messageId: 'sidebar.players',
    type: 'item',
    url: '/player/players',
    icon: 'surround_sound',
    auth: ['admin'],
  },
  {
    id: 'games',
    title: 'Spieleliste',
    messageId: 'sidebar.game',
    type: 'item',
    url: '/game/games',
    icon: 'gamepad',
    auth: ['admin']
  },
  {
    id: 'accounts',
    title: 'Accountverwaltung',
    messageId: 'sidebar.account',
    type: 'collapse',
    icon: 'view_list',
    auth: ['admin'],
    children: [
      {
        id: 'accountslist',
        title: 'Accountliste',
        messageId: 'sidebar.account.accountslist',
        type: 'item',
        url: '/account/accountslist',
      },
      {
        id: 'accountcreate',
        title: 'Account erstellen',
        messageId: 'sidebar.account.accountcreate',
        type: 'item',
        url: '/account/account',
      }
    ],
  },
  {
    id: 'info',
    title: 'Infoverwaltung',
    messageId: 'sidebar.info',
    type: 'collapse',
    icon: 'notifications_none',
    auth: ['admin'],
    children: [
      {
        id: 'infoslist',
        title: 'Infoliste',
        messageId: 'sidebar.info.infoslist',
        type: 'item',
        url: '/info/infoslist',
      },
      {
        id: 'infocreate',
        title: 'Info erstellen',
        messageId: 'sidebar.info.infocreate',
        type: 'item',
        url: '/info/info',
      }
    ],
  },
  {
    id: 'abuse',
    title: 'Meldungen',
    messageId: 'sidebar.abuse',
    type: 'item',
    url: '/abuse/abuselist',
    icon: 'report',
    auth: ['admin'],
  },
  {
    id: 'request',
    title: 'Anfragen',
    messageId: 'sidebar.request',
    type: 'item',
    url: '/request/requestlist',
    icon: 'list',
    auth: ['admin'],
  }
];
export default routesConfig;
