import React from 'react';

export const dashRouteConfig = [
  {
    auth: ['admin'],
    routes: [
      {
        path: '/dashboard',
        component: React.lazy(() => import('./Dashboard')),
      },
    ],
  },
];
