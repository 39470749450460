
import {_PlayerComplete, _PlayerDetails} from "./types/Player";
import { _Game, _GamesGame, _GamesRound, _Playerround } from "./types/Game";
import { Api } from "App";
import { Errors } from "data/const/Errors";
import { ErrorMsg } from "data/models/ErrorMsg";
import LogHelper from "helpers/LogHelper";

export class Game {


    /**
     * GameDetails 
     * @param gameId
     * @return Promise<boolean>
     */
    static game(gameId: string): Promise<_Game | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
                const response = await Api.get('/game/game/' + gameId + '?bypassCache=true', undefined, true);


                if (response && response['game']) {
                        //Parse JSON String "account"
                        LogHelper.logInfo('Services/Api/Game/game', 'Load game success');
                        resolve(response['game']);
                        return;
                    }
        
                    //Login failed
                    LogHelper.logInfo('Services/Api/Game/game', 'Load game failed');
                    Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                    reject(Api.error);
                    return;
                } catch (e) {
                    LogHelper.logError('Services/Api/Game/game', 'Load game failed by exception: ' + e);
                    Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                    reject(Api.error);
                    return;
                }
        });

    }
    /**
     * Rounds of Game
     * @param gameId
     * @return Promise<boolean>
     */
    static async gamerounds(gameId: any, skip:number, count?:boolean): Promise<_GamesRound[] | false> {

        return new Promise(async (resolve: any, reject:any) => {


            if(!gameId) {
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(Api.error);
                return
            }

            let qu = [];
            if(count) qu.push('count=true')
            if(skip) qu.push('skip='+skip)
    
            let query = '?' + qu.join('&');

            try {
                const response = await Api.get('/game/gamerounds/' + gameId + query, undefined, true);


                if (response && response['rounds']) {
                        //Parse JSON String "account"
                        LogHelper.logInfo('Services/Api/Game/gamerounds', 'Load gamerounds success');
                        resolve(response['rounds']);
                        return;
                    }
        
                    //Login failed
                    LogHelper.logInfo('Services/Api/Game/gamerounds', 'Load gamerounds failed');
                    Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                    reject(Api.error);
                    return;
                } catch (e) {
                    LogHelper.logError('Services/Api/Game/gamerounds', 'Load gamerounds failed by exception: ' + e);
                    Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                    reject(Api.error);
                    return;
                }
        });

    }


    /**
     * Round
     * @param roundId
     * @return Promise<boolean>
     */
    static async round(roundId: string): Promise<_GamesRound | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
                const response = await Api.get('/game/round/' + roundId, undefined, true);

                if (response && response['round']) {
                        //Parse JSON String "account"
                        LogHelper.logInfo('Services/Api/Game/round', 'Load round success');
                        resolve(response['round']);
                        return;
                    }
        
                    //Login failed
                    LogHelper.logInfo('Services/Api/Game/round', 'Load round failed');
                    Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                    reject(Api.error);
                    return;
                } catch (e) {
                    LogHelper.logError('Services/Api/Game/round', 'Load round failed by exception: ' + e);
                    Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                    reject(Api.error);
                    return;
                }
        });

    }



    /**
     * PlayerComplete of Player
     * @param playerId
     * @return Promise<boolean>
     */
    static async playerComplete(playerId: string): Promise<_PlayerComplete | false> {


        return new Promise(async (resolve: any, reject:any) => {


            try {
                const response = await Api.get('/player/playercomplete/' + playerId, undefined, true);

                if (response && response['profile']) {
                        //Parse JSON String "account"
                        let playerDetails = await JSON.parse(response['profile']);

                        LogHelper.logInfo('Services/Api/Player/playerComplete', 'Load player success');
                        resolve(playerDetails);
                        return;
                }

                //Login failed
                LogHelper.logInfo('Services/Api/Player/playerComplete', 'Load player failed');
                Api.error = new ErrorMsg(Errors.API_PLAYER__LOAD_PLAYER_FAILED);
                reject(Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Player/playerComplete', 'Load player failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API_PLAYER__LOAD_PLAYER_FAILED_BY_EXC, e);
                reject(Api.error);
                return;
            }

        });

    }
    
    
    /**
     * GameRounds of Player
     * @param playerId
     * @return Promise<boolean>
     */
    static async games(skip:number, search?:string, sort?:string, desc?:boolean, count?:boolean): Promise<_GamesGame[] | false> {

        let qu = [];
        if(sort) qu.push('sort='+sort)
        if(desc) qu.push('desc=true')
        if(count) qu.push('count=true')
        if(skip) qu.push('skip='+skip)
        qu.push('bypassCache=true')
        let query = '?' + qu.join('&');

        return new Promise(async (resolve: any, reject:any) => {
            try {                   
                const response = search ? await Api.get('/game/games/' + search + query, undefined, true) : await Api.get('/game/games' + query, undefined, true);
                if (response && response['games']) {
                        //Parse JSON String "account"
                        let games = response['games'];
                        LogHelper.logInfo('Services/Api/Game/games', 'Load games success');
                        resolve(games);
                        return;
                    }
        
                    //Login failed
                    LogHelper.logError('Services/Api/Game/games', 'Load games failed', response);
                    Api.error = new ErrorMsg(Errors.API__LOAD_GAMES_FAILED);
                    reject(Api.error);
                    return;
                } catch (e) {
                    LogHelper.logError('Services/Api/Game/games', 'Load games failed by exception: ', e);
                    Api.error = new ErrorMsg(Errors.API__LOAD_GAMES_FAILED, e);
                    reject(Api.error);
                    return;
                }
        });

    }
    
    /**
     * GameRounds of Player
     * @param playerId
     * @return Promise<boolean>
     */
    static async player_games(playerId: string, skip:number, search?:string, sort?:string, desc?:boolean, count?:boolean): Promise<_GamesGame[] | false> {

        let qu = [];
        if(sort) qu.push('sort='+sort)
        if(desc) qu.push('desc=true')
        if(count) qu.push('count=true')
        if(skip) qu.push('skip='+skip)
        qu.push('bypassCache=true')

        let query = '?' + qu.join('&');

        return new Promise(async (resolve: any, reject:any) => {
            try {                   
                const response = search ? await Api.get('/player/games/' + playerId + '/' + search + query, undefined, true) : await Api.get('/player/games/' + playerId + query, undefined, true);
                if (response && response['games']) {
                        //Parse JSON String "account"
                        let games = response['games'];
                        LogHelper.logInfo('Services/Api/Player/player_games', 'Load player games success');
                        resolve(games);
                        return;
                    }
        
                    //Login failed
                    LogHelper.logError('Services/Api/Player/player_games', 'Load player games failed', response);
                    Api.error = new ErrorMsg(Errors.API__LOAD_GAMES_FAILED);
                    reject(Api.error);
                    return;
                } catch (e) {
                    LogHelper.logError('Services/Api/Player/player_games', 'Load player games failed by exception: ', e);
                    Api.error = new ErrorMsg(Errors.API__LOAD_GAMES_FAILED, e);
                    reject(Api.error);
                    return;
                }
        });

    }
    
    
    /**
     * GameRounds of Player
     * @param playerId
     * @return Promise<boolean>
     */
    static async playersMeta(playerIds: string): Promise<_PlayerComplete | false> {


        return new Promise(async (resolve: any, reject:any) => {
            try {                   
                const response = await Api.get('/player/playersmeta/' + playerIds, undefined, true);
                if (response && response['players']) {
                        //Parse JSON String "account"
                        let players = response['players'];
                        LogHelper.logInfo('Services/Api/Player/playersMeta', 'Load playersMeta success');
                        resolve(players);
                        return;
                    }
        
                    //Login failed
                    LogHelper.logError('Services/Api/Player/player_games', 'Load playersMeta failed', response);
                    Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                    reject(Api.error);
                    return;
                } catch (e) {
                    LogHelper.logError('Services/Api/Player/playersMeta', 'Load playersmeta failed by exception: ', e);
                    Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                    reject(Api.error);
                    return;
                }
        });


    }



    /**
     * Account of User
     * @param accountId
     * @return Promise<boolean>
     */
     static gameUpdate(gameId: number, data:any): Promise<_PlayerDetails | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
            const response = await Api.post('/admin/game/gameUpdate/' + gameId, data, true);


                if (response && response.game) {
                        //Parse JSON String "account"
                        let gamedetails = response.game;
                        LogHelper.logInfo('Services/Api/Game/gameUpdate', 'update game success');
                        resolve(gamedetails);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Game/gameUpdate', 'update game failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(response ?? Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Game/gameUpdate', 'update game failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }
}
