import React from 'react';

export const abuseRouteConfig = [
  {
    auth: ['admin'],
    routes: [
      {
        path: '/abuse/abuselist',
        component: React.lazy(() => import('./AbuseList')),
      },
    ],
  },
  {
    auth: ['admin'],
    routes: [
      {
        path: '/abuse/abuse/:id',
        component: React.lazy(() => import('./AbuseEdit')),
      },
    ],
  },
];
