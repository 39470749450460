import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import { AppDispatch, RootState } from 'store/store';
import { sessionStoreAction } from 'store/session.store';

export const useAuthToken = (): [boolean, any | undefined] => {
  const storeDispatch: AppDispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const storeAcc = useSelector((state: RootState) => state.session.account);


  useEffect(() => {
    const validateAuth = async () => {
//      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      if (!token) {
        storeDispatch<any>(sessionStoreAction.logoutUser());
        return;
      }
    //  dispatch(setJWTToken(token));
      try {
        storeDispatch<any>(sessionStoreAction.checkLogin(token));
        
        /*dispatch({
          type: UPDATE_AUTH_USER,
          payload: {
            authType: AuthType.JWT_AUTH,
            displayName: res.data.name,
            email: res.data.email,
            role: defaultUser.role,
            token: res.data._id,
            photoURL: res.data.avatar,
          },
        }); */
        return;
      } catch (err) {
       // dispatch(fetchSuccess());
        return;
      }
    };

    const checkAuth = () => {
      Promise.all([validateAuth()]).then(() => {
        setLoading(false);
      });
    };
    checkAuth();
  }, [storeDispatch]);

  return [loading, storeAcc];
};

export const useAuthUser = (): any | null => {
  const storeAcc = useSelector((state: RootState) => state.session.account);
  if (storeAcc) {
    return storeAcc;
  }
  return null;
};
